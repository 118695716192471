// @mui
// import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Switch,
  Container,
  Typography,
  Stack,
  Card,
  Button,
  Chip,
  Divider,
  IconButton,
  Breadcrumbs,
} from "@mui/material";
import { styled } from "@mui/styles";
import WestIcon from "@mui/icons-material/West";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useContext, useEffect, useState } from "react";
// import Label from '../../components/Label';
// import Iconify from '../../components/Iconify';
import axios from "axios";
import jwt from "jwt-decode";
import { AppContext } from "../../context/appContext";
import { Link, useHistory } from "react-router-dom";
import PricingPlanCard from "./PricingPlanCard";
import ChoosePlane from "./ChoosePlane";
import PricingPlaneNotFound from "./PricingPlaneNotFound";
import CurrentPlan from "./CurrentPlan";
import UpgradePlan from "./UpgradePlan";
import CancelPlan from "./CancelPlan";

// ----------------------------------------------------------------------

const RootStyle = styled(Container)(({ theme }) => ({
  minHeight: "100%",
  // height: "100%",
  //   paddingTop: theme.spacing(15),
  //   paddingBottom: theme.spacing(10),
  // paddingBottom: "10px",
  marginTop: "1rem",
  height: "calc(100vh - 4rem)",
}));

// ----------------------------------------------------------------------

// UpgradeSubscription.getLayout = function getLayout(page) {
//   return <Layout variant="logoOnly">{page}</Layout>;
// };

// ----------------------------------------------------------------------

export default function UpgradeSubscription() {
  const history = useHistory();
  const [pricing, setPricing] = useState([]);
  const [plan, setPlan] = useState(null);
  let token = localStorage.getItem("7-star-training");
  let decodedToken = token ? jwt(token.replace("Bearer ", "")) : null; // decode your token here
  const { data } = useContext(AppContext);

  useEffect(async () => {
    if (!token) {
      history.push(`/`);
    } else {
      try {
        await axios
          .get(`saas/pricing`, {
            config: { handlerEnabled: true },
          })
          .then((response) => {
            setPricing(response.data.data);
            // document.location.reload(true)
          });
      } catch (e) {
        if (e.response && e.response.data) {
        }
      }
    }
  }, []);

  // useEffect(() => {
  //   if (data && data.tenant.subscription.status == "active") {
  //     history.push(`/${data.role}/${data._id}`);
  //   }
  // }, [data]);
  // const onUpgradePlan=()=>{

  // }

  return (
    // <Page title="UpgradeSubscription">
    <RootStyle>
      {/* {decodedToken && decodedToken.role == "admin" ? (
        <ChoosePlane pricing={pricing} />
      ) : (
        <PricingPlaneNotFound />
      )} */}

      <Grid container spacing={2} height="100%">
        <Grid item xs={5} display="flex" alignItems="center" height="100%">
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              padding: "1rem 3rem",
            }}
          >
            <Typography variant="h4" component="h4" marginBottom="1.5rem">
              7 Star Training Ltd partners with Stripe for simplified billing.
            </Typography>
            <Button
              sx={{ fontSize: ".75rem", padding: ".75rem 1rem" }}
              startIcon={<WestIcon />}
            >
              Return to 7 Star Training Ltd
            </Button>
          </Box>
        </Grid>
        <Grid item xs={7} sx={{ backgroundColor: "white" }} height="100%">
          {!plan && <CurrentPlan setPlan={setPlan} />}
          {plan === "Upgrade" && <UpgradePlan setPlan={setPlan} plan={plan} />}
          {plan === "Cancel" && <CancelPlan setPlan={setPlan} plan={plan} />}
        </Grid>
      </Grid>
    </RootStyle>
    // </Page>
  );
}
