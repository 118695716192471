import React, { useState, useEffect, useContext, Fragment } from "react";
import "./SignUp.css";

import CssBaseline from "@mui/material/CssBaseline";
import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Container,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  Avatar,
  Button,
  Card,
  CardContent,
} from "@mui/material";

import { Box, Stack } from "@mui/system";
import AddLearnerPage from "../AddLearner/AddLearnerPage";

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: "#5335CA !important",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  button: {
    background: "#5335CA !important",
    color: "white !important",
    transition: "background .3s linear",
    "&:hover": {
      background: "#4b27dd !important",
    },
  },
}));

export const SignUp = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Box sx={{ backgroundColor: "#e5e5e5" }}>
        <Container maxWidth="sm">
          <CssBaseline />
          {/* <Box
            // sx={{
            //   width: {
            //     xs: "19.688rem",
            //     sm: "5rem",
            //     md: "15rem",
            //     lg: "30rem",
            //     xl: "5rem",
            //   },
            // }}
          > */}
          <Box
            sx={{
              height: {
                xs: "3rem",
                sm: "3rem",
                md: "3.5rem",
                lg: "6rem",
                xl: "8rem",
              },
            }}
          />
          <Box
            sx={{
              border: "1px solid grey",
              padding: { xs: 1, sm: 2, md: 3 },
              borderRadius: "25px",
              boxShadow: "1px 1px 7px 6px rgba(0,0,0,0.1)",
            }}
          >
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
            </Stack>
            <Typography
              component="h1"
              variant="h5"
              sx={{ textAlign: "center" }}
            >
              Sign up
            </Typography>
            <AddLearnerPage />
          </Box>
          <Box sx={{ height: "2rem" }} />
          {/* </Box> */}
        </Container>
      </Box>
    </Fragment>
  );
};
