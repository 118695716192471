import React, { useState, useContext } from "react";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { AppContext } from "../../context/appContext";
import { validateEmail } from "../../utils/functions";
import {
  Modal,
  Box,
  Grid,
  FormGroup,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { capitalCase } from "capital-case";
import { useDispatch, useSelector } from "../../redux/store";
import { getAllFaculties, setAllFaculties } from "../../redux/slices/faculty";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 550,
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: 2,
  p: 4,
};

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 20,
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
    cursor: "pointer",
    zIndex: 10,
  },
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
  buttonn: {
    background: "#939393",
    "&:hover": {
      background: "#888888",
    },
    marginRight: 10,
  },
}));

const AddFaculty = ({
  open,
  close,
  facultyRoles,
  setSuperAdminAlertModal,
  newFacultyValidations,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { faculties } = useSelector((state) => state.faculty);

  // data, setData, handleToggle, updateMessage, getUserData
  const { handleToggle, updateMessage } = useContext(AppContext);
  const [facultyValidations, setFacultyValidations] = useState(
    newFacultyValidations
  );
  const [newFaculty, setNewFaculty] = useState({
    name: "",
    role: "",
    email: "",
    key: "",
  });

  // FormHandler For Add New Faculty Modal
  const newFacultyChangeHandler = (e) => {
    let { name, value } = e.target;

    if (!name) {
      if (
        value.toLowerCase() === "super admin" &&
        newFaculty.role !== "super admin"
      ) {
        setSuperAdminAlertModal(true);
      }
      setNewFaculty({
        ...newFaculty,
        role: value.toLowerCase(),
      });

      if (value && facultyValidations.role) {
        setFacultyValidations({
          ...facultyValidations,
          role: false,
        });
      }
    } else {
      setNewFaculty({
        ...newFaculty,
        [name]: value,
      });
      if (value && facultyValidations[name]) {
        setFacultyValidations({
          ...facultyValidations,
          [name]: false,
        });
      }
    }
  };

  // To Reset The Add New Faculty Modal
  const resetAddNewFaculty = () => {
    if (newFaculty.key === "") {
      setNewFaculty({
        name: "",
        role: "",
        email: "",
        key: 1,
      });
    } else {
      setNewFaculty({
        name: "",
        role: "",
        email: "",
        key: "",
      });
    }
    setFacultyValidations(newFacultyValidations);
  };

  // To Add A New Faculty
  const addNewFaculty = async () => {
    // 1- Validations
    if (newFaculty.name === "") {
      setFacultyValidations({
        ...facultyValidations,
        name: true,
      });
      return;
    }
    if (newFaculty.role === "") {
      setFacultyValidations({
        ...facultyValidations,
        role: true,
      });
      return;
    }
    if (newFaculty.email === "" || !validateEmail(newFaculty.email)) {
      setFacultyValidations({
        ...facultyValidations,
        email: true,
      });
      return;
    }

    // 2- Api
    try {
      handleToggle(true);
      await axios
        .post("faculty/register", newFaculty, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          updateMessage(response.data.message);
          if (newFaculty.role == "super admin") {
            window.location.reload();
            return;
          }
          // dispatch(setAllFaculties(response.data.data));
          dispatch(getAllFaculties());
          resetAddNewFaculty();
          close();
          handleToggle(false);
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "scroll" }}
    >
      <Box sx={modalStyle} className="modalContainer">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="modalHeader"
        >
          <Typography id="modal-modal-title" variant="h5" component="h3">
            Create New Faculty
          </Typography>
          <CloseIcon className={classes.icons} title="Close" onClick={close} />
        </Grid>
        <FormGroup sx={{ paddingTop: 3 }}>
          <Grid container spacing={3} className={classes.field}>
            <Grid item sm={12} md={12} className="fullWidthInput">
              <TextField
                label="Name"
                variant="outlined"
                name="name"
                value={newFaculty.name}
                onChange={(e) => newFacultyChangeHandler(e)}
                error={facultyValidations.name}
                helperText={facultyValidations.name && "This can't be empty."}
              />
            </Grid>
          </Grid>
          <Autocomplete
            key={newFaculty.key}
            className={`${classes.field} materialRolesAutocomplete`}
            options={facultyRoles}
            getOptionLabel={(option) => capitalCase(option.title, "PascalCase")}
            onSelect={(e) => newFacultyChangeHandler(e, "role")}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "disabled",
                }}
                label="Faculty Role"
                variant="outlined"
                value={newFaculty.role}
                error={facultyValidations.role}
                helperText={facultyValidations.role && "This can't be empty."}
              />
            )}
          />
          <TextField
            label="Work Email"
            variant="outlined"
            className={classes.field}
            name="email"
            value={newFaculty.email}
            onChange={(e) => newFacultyChangeHandler(e)}
            error={facultyValidations.email}
            helperText={
              facultyValidations.email && "This must be in email format."
            }
          />
          <div className="modalFooter">
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button
                variant="contained"
                className={classes.button}
                onClick={addNewFaculty}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                className={classes.buttonn}
                onClick={resetAddNewFaculty}
              >
                Reset
              </Button>
            </div>
          </div>
        </FormGroup>
      </Box>
    </Modal>
  );
};

export default AddFaculty;
