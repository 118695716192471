import axios from "axios";

const isHandlerEnabled = (config = {}) => {
  // check if the handler enabled property is true or not
  return config.hasOwnProperty(`handlerEnabled`) && config.handlerEnabled
    ? true
    : false;
};

const requestHandler = (request) => {
  if (isHandlerEnabled(request.config)) {
    // console.log(sessionStorage.getItem("7-star-training-temp"))
    // if isHandlerEnabled is true send authorization token in headers
    if (localStorage.getItem("7-star-training")) {
      request.headers["Authorization"] =
        localStorage.getItem("7-star-training");
    } else if (sessionStorage.getItem("7-star-training-temp")) {
      request.headers["Authorization"] = sessionStorage.getItem(
        "7-star-training-temp"
      );
      sessionStorage.removeItem("7-star-training-temp");
    }
  }
  return request;
};

const Axios = () => {
  // Request Interceptors(before we send a request)
  axios.defaults.baseURL = process.env.REACT_APP_BASEURL || "/api/";

  axios.interceptors.request.use(
    (req) => {
      // check if the user has an internet connection
      if (navigator.onLine) {
        // if user has internet connection then send request
        requestHandler(req);
        // store.dispatch({ type: SHOW_LOADER })
      } else {
        // internet connectivity error
        throw new axios.Cancel(
          "Operation cancelled due to disconnectivity of Internet"
        );
      }
      return req;
    },
    (error) => {
      // if the request is failed to send
      // store.dispatch({ type: HIDE_LOADER })
      return Promise.reject(error);
    }
  );

  // response interceptor (when responseis sent back)
  axios.interceptors.response.use(
    (res) => {
      // setTimeout(() => {
      //     store.dispatch({ type: HIDE_LOADER })
      // })
      return res;
    },
    (error) => {
      // setTimeout(() => {
      //     store.dispatch({ type: HIDE_LOADER })
      // })
      if (axios.isCancel(error)) {
      } else {
        // console.log("Error is: ", error, "Message is: ", error.message, error.response.data.message);
        if (error.message === `Network Error`) {
          // store.dispatch(showToastMessage("'Server Is Down, Try Latter.'"))
          // alert("Server Is Down, Try Latter.");
          // console.log("Server Is Down, Try Latter.");
        } else if (error.response.data.message === `Page is not accessible`) {
          window.location.replace("/");
        } else if (
          error.message === `Request failed with status code 401` ||
          // error.message === `Auth Token Is Not Supplied`
          error.message === `Please login again`
        ) {
          //   refresh token case
          setTimeout(() => {
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();
          }, 2000);
        }
      }
      return Promise.reject(error);
    }
  );
};

export default Axios;
