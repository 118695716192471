// @mui
// import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Switch,
  Container,
  Typography,
  Stack,
  Card,
  Button,
  Chip,
} from "@mui/material";
import { styled } from "@mui/styles";

import { useContext, useEffect, useState } from "react";
// import Label from '../../components/Label';
// import Iconify from '../../components/Iconify';
import axios from "axios";
import jwt from "jwt-decode";
import { AppContext } from "../../context/appContext";
import { useHistory } from "react-router-dom";
import PricingPlanCard from "./PricingPlanCard";
import ChoosePlane from "./ChoosePlane";
import PricingPlaneNotFound from "./PricingPlaneNotFound";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  minHeight: "100%",
  //   paddingTop: theme.spacing(15),
  //   paddingBottom: theme.spacing(10),
  paddingTop: 50,
  paddingBottom: "10px",
}));

// ----------------------------------------------------------------------

// Pricing.getLayout = function getLayout(page) {
//   return <Layout variant="logoOnly">{page}</Layout>;
// };

// ----------------------------------------------------------------------

export default function Pricing() {
  const history = useHistory();
  const [pricing, setPricing] = useState([]);
  let token = localStorage.getItem("7-star-training");
  let decodedToken = token ? jwt(token.replace("Bearer ", "")) : null; // decode your token here
  const { data } = useContext(AppContext);
  // console.log(pricing);

  useEffect(async () => {
    if (!token) {
      history.push(`/`);
    } else {
      try {
        await axios
          .get(`saas/pricing`, {
            config: { handlerEnabled: true },
          })
          .then((response) => {
            setPricing(response);
            // console.log("rwewffsf", response);
          });
      } catch (e) {
        if (e.response && e.response.data) {
        }
      }
    }
  }, []);

  useEffect(() => {
    if (data && data.subscription.status == "active") {
      // history.push("/home");
      // history.push(`/${data.role}/${data._id}`);
      history.push(`/learner/${data._id}`);
    }
  }, [data]);

  return (
    // <Page title="Pricing">
    <RootStyle>
      <ChoosePlane pricing={pricing} />
    </RootStyle>
    // </Page>
  );
}
