import React, { useContext, useEffect, useState } from "react";
import { Typography, Box, Backdrop, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import sucess from "../../Assets/greenTick.webp";
import { AppContext } from "../../context/appContext";
import { useHistory } from 'react-router-dom';
//
function AuthenticateEmailUpdate() {
  const { data, handleToggle, updateMessage } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);

  const url = window.location.href;
  const parts = url.split("/verify-email/");
  const code = parts[1];
  const history = useHistory();

  const handleClick = () => {
    history.push('/');
  };

  const verify = async () => {
    try {
      // handleToggle(true);
      await axios
        .post(
          "learner/verify-email",
          { token: code },
          {
            config: { handlerEnabled: true },
          }
        )
        .then((response) => {
          setIsLoading(false);
        });
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    if (data) {
      verify();
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Backdrop open={true}>
            <CircularProgress size="2.5rem" thickness={4} color="inherit" />
          </Backdrop>
          {/* <Loader/> */}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "89vh",
          }}
        >
          <img
            src={sucess}
            alt="sucess"
            style={{ width: "3rem", height: "3rem", marginBottom: "10px" }}
          />
          <Typography>Your Email has been updated successfully</Typography>
          <Button variant="contained" onClick={handleClick} sx={{
            mt:2,
            background: "#5335CA",
            "&:hover": {
              background: "#5335CA",
            },
          }}>
            Back To Home
          </Button>
        </Box>
      )}
    </>
  );
}

export default AuthenticateEmailUpdate;
