import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useLayoutEffect,
} from "react";
import "./index.css";
import AddLearner from "../../components/AddLearner/AddLearner";
import AddFaculty from "../../components/AddFaculty/AddFaculty";
import UpdateFaculty from "../../components/UpdateFaculty/UpdateFaculty";
import UpdateLearner from "../../components/UpdateLearner/UpdateLearner";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import RefreshButton from "../../components/RefreshButton/Refresh";
import ProfileContainer from "../../components/ProfileContainer/ProfileContainer";
import HelpAndSupport from "../../components/HelpAndSupport/HelpAndSupport";
import SubscriptionInfo from "../../components/SubscriptionInfo/SubscriptionInfo";
import { LearnerList } from "../../components/LearnerList/LearnerList";
import FacultyListNew from "../../components/FacultyListNew/FacultyListNew";
import { Charts } from "../../components/Charts/Charts";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import enLocale from "date-fns/locale/en-GB";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Container,
  Grid,
  Box,
  Chip,
  FormControl,
  TextField,
  Typography,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Skeleton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";

// import dayjs from 'dayjs';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { isEqual } from "lodash";
import LearnerListNew from "../../components/LearnerListNew/LearnerListNew";
import { useDispatch, useSelector } from "../../redux/store";
import { getLearners, setAllLearners } from "../../redux/slices/learner";
import { getAllFaculties, setAllFaculties } from "../../redux/slices/faculty";
import { useTheme } from "@mui/material";
import { RestrictFeature } from "../../components/RestrictFeature/RestrictFeature";
import UpgradePlanModal from "../../components/UpgradePlanModal/UpgradePlanModal";
// import Skeleton from '@mui/lab/Skeleton';

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 550,
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: 2,
  p: 4,
};

const useStyles = makeStyles(() => ({
  divStyle: {
    display: "flex",
  },
  divStyle2: {
    display: "flex",
    justifyContent: "space-between",
  },
  divStyle3: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #d9d9d9",
  },

  divStyle4: {
    display: "flex",
    justifyContent: "space-between",
  },
  labelstyle: {
    paddingLeft: "5",
  },
  field: {
    marginBottom: 20,
  },
  boxStyle: {
    marginTop: 20,
    padding: 39,
    borderRadius: 4,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    "@media (max-width: 768px)": {
      padding: 20,
    },
  },
  boxStyleDateBox: {
    padding: 20,
    borderRadius: 4,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
  },
  cardStyle: {
    marginTop: 20,
    borderRadius: 4,
  },
  cardStyleLearnersData: {
    marginTop: 20,
    borderRadius: 4,
  },
  cardStyleLearnersProgressData: {
    marginTop: 20,
    borderRadius: 4,
  },
  cardStyleDateBox: {
    marginTop: 20,
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
    cursor: "pointer",
    zIndex: 10,
  },
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
  buttonn: {
    background: "#939393",
    "&:hover": {
      background: "#888888",
    },
    marginRight: 10,
  },
  form: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  barOne: {
    color: "#B4A6EB",
  },
  barTwo: {
    color: "#7CBD1A",
  },
  barThree: {
    color: "#BD1A41",
  },
  barFour: {
    color: "#0000FF",
  },
  barFive: {
    color: "#FACE6B",
  },
  barSix: {
    color: "#BABBBC",
  },
  barSeven: {
    color: "#3ACFF8",
  },
  skeleton: {
    backgroundColor: "#fff",
    padding: "20px",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 4,
    marginTop: 20,
  },
}));

export const AdminPage = () => {
  const classes = useStyles();

  // data, setData, handleToggle, updateMessage, getUserData
  const { data, handleToggle, updateMessage } = useContext(AppContext);
  const dispatch = useDispatch();
  const { learners } = useSelector((state) => state.learner);
  const { faculties } = useSelector((state) => state.faculty);

  // Variables
  let { id } = useParams();
  let helpAndSupportMessage =
    "Feel free to contact us to get help and support from 7 Star Training’s technical support team.";
  let subscriptionInfoMessage =
    "Summary information of your organisation's subscriptions.";
  let url = "faculty/admin/".concat(id);
  const isDesktop = window.innerWidth > 899 ? true : false;
  const newLearnerValidations = {
    name: false,
    email: false,
    trainingProviderName: false,
    employerName: false,
    apprenticeshipStandard: false,
    startDate: false,
    endDate: false,
    durationInMonths: false,
    durationInHours: false,
    trainingPercentage: false,
    targetMonths: false,
  };
  const newFacultyValidations = {
    name: false,
    role: false,
    email: false,
  };

  const [tempFaculty, setTempFaculty] = useState({
    email: "",
    name: "",
    role: "",
    _id: "",
    active: true,
    attribute: "",
  });
  const [facultyRoles, setFacultyRoles] = useState([
    { title: "admin" },
    { title: "coach" },
    { title: "mentor" },
    { title: "manager" },
    { title: "visitor" },
    { title: "trainer" },
  ]);

  const [openAddFacultyModal, setOpenAddFacultyModal] = useState(false);
  const [openUpgradePlanModal, setopenUpgradePlanModal] = useState(false);
  const [openUpdateFacultyModal, setOpenUpdateFacultyModal] = useState(false);
  const [entity, setEntity] = useState();
  const [toDelete, setToDelete] = useState();

  // States For Learners
  const [tempLearner, setTempLearner] = useState({
    uln: "",
    name: "",
    email: "",
    trainingProviderName: "",
    employerName: "",
    coach: "",
    manager: "",
    mentor: "",
    visitor: "",
    trainer: "",
    apprenticeshipStandard: "",
    coachMentor: [],
    emailToMentor: true,
    emailToCoach: true,
    status: "",
    _id: "",
    active: true,
  });
  const [openUpdateLearnerModal, setOpenUpdateLearnerModal] = useState(false);
  const [openAddLearner, setOpenAddLearner] = useState(false);

  // States For Reports
  const [otherReports, setOtherReports] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  // Miscellaneous States
  const [adminData, setAdminData] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [superAdminAlertModal, setSuperAdminAlertModal] = useState(false);

  // To set faculty roles base on admin or super admin
  useEffect(() => {
    if (data && data.attribute === "super admin") {
      setFacultyRoles([
        { title: "super admin" },
        { title: "admin" },
        { title: "coach" },
        { title: "mentor" },
        { title: "manager" },
        { title: "visitor" },
        { title: "trainer" },
      ]);
    } else {
      setFacultyRoles([
        // { title: "admin" },
        { title: "coach" },
        { title: "mentor" },
        { title: "manager" },
        { title: "visitor" },
        { title: "trainer" },
      ]);
    }
  }, [data]);

  // To Open Edit/ Update Faculty Modal
  const updateFacultyModal = (faculty) => {
    setTempFaculty({
      email: faculty.email,
      name: faculty.name,
      role: faculty.role,
      _id: faculty._id,
      active: faculty.active,
      attribute: faculty.attribute,
    });

    setOpenUpdateFacultyModal(!openUpdateFacultyModal);
  };

  // To Open Edit/ Update Learner Modal
  const updateLearnerModal = (learner) => {
    setTempLearner({
      uln: learner.uln,
      name: learner.name,
      email: learner.email,
      trainingProviderName: learner.trainingProviderName,
      employerName: learner.employerName,
      coach:
        learner?.coachMentor?.filter((faculty) => faculty?.role === "coach")[0]
          ?.email || "",
      manager:
        learner?.coachMentor?.filter(
          (faculty) => faculty?.role === "manager"
        )[0]?.email || "",
      mentor:
        learner?.coachMentor?.filter((faculty) => faculty?.role === "mentor")[0]
          ?.email || "",
      visitor:
        learner?.coachMentor?.filter(
          (faculty) => faculty?.role === "visitor"
        )[0]?.email || "",
      trainer:
        learner?.coachMentor?.filter(
          (faculty) => faculty?.role === "trainer"
        )[0]?.email || "",
      coachMentor: learner.coachMentor,
      apprenticeshipStandard: learner.apprenticeshipStandard,
      emailToMentor: false,
      emailToCoach: false,
      status: learner.status,
      active: learner.active,
    });

    setOpenUpdateLearnerModal(!openUpdateLearnerModal);
  };

  // To Delete Faculty
  const deleteFaculty = async () => {
    let data = {
      toDelete,
    };

    try {
      handleToggle(true);
      await axios
        .delete("faculty", {
          data: data,
          config: {
            handlerEnabled: true,
          },
        })
        .then((response) => {
          // dispatch(setAllFaculties(response.data.data));
          dispatch(getAllFaculties());
          handleToggle(false);
          updateMessage(response.data.message);
          setConfirmationModal(false);
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  // To Delete Learner
  const deleteLearner = async () => {
    let data = {
      toDelete,
    };

    try {
      handleToggle(true);
      await axios
        .delete("learner", {
          data: data,
          config: {
            handlerEnabled: true,
          },
        })
        .then((response) => {
          // dispatch(setAllLearners(response.data.data));
          dispatch(getLearners(id));
          handleToggle(false);
          updateMessage(response.data.message);
          setConfirmationModal(false);
          fetchLearners();
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  // To Download The Learner Report
  const downloadOtherReports = async () => {
    // let data = {
    //   startDate: otherReports.startDate.toLocaleDateString("en-GB", {
    //     timeZone: "Europe/London",
    //   }),
    //   endDate: otherReports.endDate.toLocaleDateString("en-GB", {
    //     timeZone: "Europe/London",
    //   }),
    // };

    let data = {
      startDate: otherReports.startDate.toLocaleDateString("en-GB"),
      endDate: otherReports.endDate.toLocaleDateString("en-GB"),
    };

    try {
      handleToggle(true);
      await axios
        .post("learner/generate-cohort-OTJ-report", data, {
          config: { handlerEnabled: true },
          responseType: "blob",
        })
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          // window.open(fileURL, "_blank");
          // Code for download
          let link = document.createElement("a");
          document.documentElement.append(link);

          link.setAttribute("download", `Cohort report`);
          link.href = fileURL;

          // Auto click the link
          link.click();
          handleToggle(false);
          updateMessage(response.data.message);
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  // To Check If The User Auth Token Is Valid/Saved & Then Get Admin Data
  const checkAuth = async () => {
    try {
      await axios
        .get(url, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          if (!isEqual(adminData, response.data.data)) {
            setAdminData(response.data.data);
          }
        });
    } catch (e) {
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  // To Check If The User Auth Token Is Saved & Then Get Admin Data
  useEffect(() => {
    checkAuth();
  }, [faculties, learners]); // eslint-disable-line react-hooks/exhaustive-deps

  // To Fetch All Faculty
  const fetchFaculties = async () => {
    await dispatch(getAllFaculties());
  };
  // To Fetch All Learners
  const fetchLearners = async () => {
    dispatch(getLearners(id));
  };

  //To Export Report

  const exportReport = async () => {
    try {
      handleToggle(true);
      await axios
        .get(`faculty/csv`, {
          config: { handlerEnabled: true },
          responseType: "blob",
        })
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: "text/csv" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          // window.open(fileURL, "_blank");

          // Code for download
          let link = document.createElement("a");
          document.documentElement.append(link);

          // link.setAttribute("download", `${learner.name}'s report`);
          link.setAttribute("download", `Report`);
          link.href = fileURL;

          // Auto click the link
          link.click();
          handleToggle(false);
          updateMessage(response.data.message);
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  useEffect(() => {
    // To Fetch All Faculties
    fetchFaculties();
    // To Fetch All Learners
    // fetchLearners();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const openConfirmationModal = (role) => {
    setEntity(role.role);
    setToDelete(role._id);
    setConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModal(false);
  };

  // To Fetch All Data When User Back TYo Page After Visiting Another Tab
  const onVisibilityChange = async () => {
    let hidden;

    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
    }

    if (!document[hidden]) {
      // To Fetch All Faculties
      fetchFaculties();
      // To Fetch All Learners
      fetchLearners();
    }
  };

  useLayoutEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);

    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const theme = useTheme();
  return (
    <Fragment>
      <Container
        component="main"
        maxWidth="xl"
        className="pd-bottom-40"
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Container maxWidth="xl">
          <Box>
            {!isDesktop && (
              <ProfileContainer userData={adminData && adminData.admin} />
            )}
          </Box>
          <Fragment>
            {data ? (
              <Box className={classes.boxStyle}>
                <div className="totalPerformance">
                  <Typography variant="h5" component="h3" sx={{ flexGrow: 1 }}>
                    Total Performance
                  </Typography>
                  <RefreshButton />
                </div>

                <Grid container className="totalPerformanceBox">
                  <Grid item xs={12} md={3}>
                    <Fragment>
                      <Typography
                        variant="h5"
                        component="h3"
                        sx={{ flexGrow: 1 }}
                      >
                        Learners signed up
                      </Typography>
                      <Typography component="p" variant="h4">
                        {adminData &&
                          adminData.overAllPerformance.learnersSignedUp}
                      </Typography>
                    </Fragment>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Fragment>
                      <Typography
                        variant="h5"
                        component="h3"
                        sx={{ flexGrow: 1 }}
                      >
                        Learners on programme
                      </Typography>
                      <Typography component="p" variant="h4">
                        {adminData &&
                          adminData.overAllPerformance.learnersOnProgramme}
                      </Typography>
                    </Fragment>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Fragment>
                      <Typography
                        variant="h5"
                        component="h3"
                        sx={{ flexGrow: 1 }}
                      >
                        Learners on break
                      </Typography>
                      <Typography component="p" variant="h4">
                        {adminData &&
                          adminData.overAllPerformance.learnersBreakProgramme}
                      </Typography>
                    </Fragment>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Fragment>
                      <Typography
                        variant="h5"
                        component="h3"
                        sx={{ flexGrow: 1 }}
                      >
                        Learners off programme
                      </Typography>
                      <Typography component="p" variant="h4">
                        {adminData &&
                          adminData.overAllPerformance.learnersOffProgramme}
                      </Typography>
                    </Fragment>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <React.Fragment>
                      <Typography
                        variant="h5"
                        component="h3"
                        sx={{ flexGrow: 1 }}
                      >
                        Learners completed
                      </Typography>
                      <Typography component="p" variant="h4">
                        {adminData &&
                          adminData.overAllPerformance.learnersCompleted}
                      </Typography>
                    </React.Fragment>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <div className={classes.skeleton}>
                <Skeleton variant="text" height={45} />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    margin: "0 -10px",
                  }}
                >
                  <div style={{ padding: "10px", flex: "1" }}>
                    <Skeleton variant="rect" height={150} />
                  </div>
                  <div style={{ padding: "10px", flex: "1" }}>
                    <Skeleton variant="rect" height={150} />
                  </div>
                  <div style={{ padding: "10px", flex: "1" }}>
                    <Skeleton variant="rect" height={150} />
                  </div>
                  <div style={{ padding: "10px", flex: "1" }}>
                    <Skeleton variant="rect" height={150} />
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        </Container>

        <Grid container className="mainSection">
          <Grid item xs={12} md={8.5}>
            <Container maxWidth="xl">
              <Box>
                <Grid item xs={12} sm={12} className={classes.boxStyle}>
                  <Fragment>
                    {data ? (
                      <Box className={`listHeader ${classes.divStyle}`}>
                        <Typography
                          variant="h5"
                          component="h3"
                          component="h3"
                          sx={{ flexGrow: 1 }}
                        >
                          All Learners
                        </Typography>
                        <RestrictFeature available={["standard", "premium"]}>
                          <Button
                            variant="contained"
                            sx={{
                              maxWidth: "xl",
                              mr: 2,
                              [theme.breakpoints.down("505")]: {
                                marginRight: 0,
                                marginBottom: 1,
                              },
                            }}
                            className={`buttonFix ${classes.button}`}
                            onClick={() => exportReport()}
                          >
                            Export Csv
                          </Button>
                        </RestrictFeature>
                        <Button
                          variant="contained"
                          className={classes.button}
                          onClick={() => setOpenAddLearner(!openAddLearner)}
                        >
                          Add New
                        </Button>
                      </Box>
                    ) : (
                      <Skeleton variant="text" height={45} />
                    )}
                  </Fragment>

                  <LearnerListNew
                    maxHeight={400}
                    deleteLearner={openConfirmationModal}
                    edit={updateLearnerModal}
                  ></LearnerListNew>
                </Grid>
              </Box>
            </Container>

            <Container maxWidth="xl">
              <Box>
                <Grid container>
                  <Grid item xs={12} sm={12} className={classes.boxStyle}>
                    <Fragment>
                      {data ? (
                        <div className={`listHeader ${classes.divStyle}`}>
                          <Typography
                            variant="h5"
                            component="h3"
                            component="h3"
                            sx={{ flexGrow: 1 }}
                          >
                            All Faculties
                          </Typography>
                          <Button
                            variant="contained"
                            className={classes.button}
                            onClick={() => setOpenAddFacultyModal(true)}
                          >
                            Add New
                          </Button>
                        </div>
                      ) : (
                        <Skeleton variant="text" height={45} />
                      )}
                    </Fragment>

                    <FacultyListNew
                      deleteFaculty={openConfirmationModal}
                      edit={updateFacultyModal}
                    ></FacultyListNew>
                  </Grid>
                </Grid>
              </Box>
            </Container>

            <Container maxWidth="xl">
              {data ? (
                <Box className={classes.cardStyleDateBox}>
                  <div className={classes.boxStyleDateBox}>
                    <Typography
                      variant="h5"
                      component="h3"
                      component="h3"
                      sx={{ flexGrow: 1 }}
                    >
                      Learners Report
                    </Typography>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.form}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} className="cohortInput">
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            locale={enLocale}
                          >
                            <DatePicker
                              label="From"
                              name="startDate"
                              showToolbar={false}
                              value={otherReports.startDate}
                              onChange={(e) => {
                                setOtherReports({
                                  ...otherReports,
                                  startDate: e.$d,
                                });
                              }}
                              onSelect={(e) => {
                                setOtherReports({
                                  ...otherReports,
                                  startDate: e.$d,
                                });
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              format="DD-MM-YYYY"
                              inputFormat="DD/MM/YYYY"
                            />
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={6} className="cohortInput">
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            locale={enLocale}
                          >
                            <DatePicker
                              label="To"
                              name="endDate"
                              showToolbar={false}
                              value={otherReports.endDate}
                              onChange={(e) => {
                                setOtherReports({
                                  ...otherReports,
                                  endDate: e.$d,
                                });
                              }}
                              onSelect={(e) => {
                                setOtherReports({
                                  ...otherReports,
                                  endDate: e.$d,
                                });
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              format="DD-MM-YYYY"
                              inputFormat="DD/MM/YYYY"
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>

                      <div className="cohortButton">
                        <Button
                          variant="contained"
                          className={classes.button}
                          onClick={downloadOtherReports}
                          startIcon={<DownloadIcon />}
                        >
                          Download
                        </Button>
                      </div>
                    </FormControl>

                    <Typography
                      variant="h5"
                      component="h3"
                      component="h3"
                      sx={{ flexGrow: 1 }}
                      style={{ color: "#2D2C40", fontSize: "10px" }}
                    >
                      The downloadable report will be presented as PDF format
                      document{" "}
                    </Typography>
                  </div>
                </Box>
              ) : (
                <div className={classes.skeleton}>
                  <Skeleton variant="text" height={45} />
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: 20,
                    }}
                  >
                    <Skeleton variant="rect" height={45} />
                    <Skeleton variant="rect" height={45} />
                  </div>
                  <Skeleton variant="text" width="30%" height={45} />
                </div>
              )}
            </Container>
          </Grid>

          <Grid item xs={12} md={3.5}>
            <Container maxWidth="xl">
              {isDesktop && (
                <ProfileContainer userData={adminData && adminData.admin} />
              )}

              {isDesktop && data && data._id === id && (
                <HelpAndSupport message={helpAndSupportMessage} />
              )}

              {data ? (
                <Box className={classes.cardStyleLearnersProgressData}>
                  <Card>
                    <CardContent className="card-content">
                      <Typography variant="h5" component="h3" gutterBottom>
                        Learners Progress
                      </Typography>
                      <Box
                        sx={{ width: "100%", marginTop: 3, marginBottom: 3 }}
                      >
                        <div className={classes.divStyle2}>
                          <Typography gutterBottom>
                            On Target
                            <Chip
                              size="small"
                              sx={{
                                marginLeft: "5px",
                                color: "#7CBD1A",
                                border: `1px solid #7CBD1A`,
                                backgroundColor: `transparent !important`,
                              }}
                              label={
                                adminData &&
                                adminData.overAllProgress.onTargetCount
                              }
                            />
                          </Typography>
                          <label>
                            {adminData && adminData.overAllProgress.onTarget}%
                          </label>
                        </div>
                        <LinearProgress
                          className={classes.barTwo}
                          color="inherit"
                          value={
                            adminData && adminData.overAllProgress.onTarget
                          }
                          variant="determinate"
                        />
                      </Box>
                      <Box
                        sx={{ width: "100%", marginTop: 3, marginBottom: 3 }}
                      >
                        <div className={classes.divStyle2}>
                          <Typography gutterBottom>
                            Below Target
                            <Chip
                              size="small"
                              sx={{
                                marginLeft: "5px",
                                color: "#BD1A41",
                                border: `1px solid #BD1A41`,
                                backgroundColor: `transparent !important`,
                              }}
                              label={
                                adminData &&
                                adminData.overAllProgress.belowTargetCount
                              }
                            />
                          </Typography>
                          <label>
                            {adminData && adminData.overAllProgress.belowTarget}
                            %
                          </label>
                        </div>
                        <LinearProgress
                          className={classes.barThree}
                          color="inherit"
                          value={
                            adminData && adminData.overAllProgress.belowTarget
                          }
                          variant="determinate"
                        />
                      </Box>
                      {/* <Box
                        sx={{ width: "100%", marginTop: 3, marginBottom: -1 }}
                      >
                        <div className={classes.divStyle3}>
        
                        <div className={classes.divStyle4}>
                         <Typography gutterBottom
                         sx={{padding:"10px 0px 0px 0px",}}
                         >On target:  
                         <label className="labelStyles">
                         {adminData && adminData.overAllProgress.onTargetCount}
                          </label>
                         </Typography>
                          
                         </div>
                         <div className={classes.divStyle4}>
                         <Typography gutterBottom
                         sx={{padding:"10px 0px 0px 0px",}}
                         >Below target:   
                         <label className="labelStyles">
                         {adminData && adminData.overAllProgress.belowTargetCount}
                          
                          </label>
                         </Typography>
                          
                         </div>
                      
                        </div>
                      </Box> */}
                      {/* <Box 
                        sx={{ width: "100%", marginTop: 3, marginBottom: 3 }}
                      >
                        <div className={classes.divStyle2}>
                          <Typography gutterBottom>Over Target</Typography>
                          <label>
                            {adminData && adminData.overAllProgress.overTarget}%
                          </label>
                        </div>
                        <LinearProgress
                          color="inherit"
                          className={classes.barFour}
                          value={
                            adminData && adminData.overAllProgress.overTarget
                          }
                          variant="determinate"
                        />
                      </Box> */}
                    </CardContent>
                  </Card>
                </Box>
              ) : (
                <div className={classes.skeleton}>
                  <Skeleton variant="text" height={50} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                </div>
              )}

              {data ? (
                <Box className={classes.cardStyleLearnersData}>
                  <Card>
                    <CardContent className="card-content">
                      <Typography variant="h5" component="h3" gutterBottom>
                        Learners Data
                      </Typography>
                      <Box
                        sx={{ width: "100%", marginTop: 3, marginBottom: 3 }}
                      >
                        <div className={classes.divStyle2}>
                          <Typography gutterBottom>On Programme</Typography>
                          <label>
                            {adminData && adminData.overAllData.onProgramme}%
                          </label>
                        </div>
                        <LinearProgress
                          color="inherit"
                          className={classes.barFive}
                          value={adminData && adminData.overAllData.onProgramme}
                          variant="determinate"
                        />
                      </Box>
                      <Box
                        sx={{ width: "100%", marginTop: 3, marginBottom: 3 }}
                      >
                        <div className={classes.divStyle2}>
                          <Typography gutterBottom>On-break</Typography>
                          <label>
                            {adminData && adminData.overAllData.onBreak}%
                          </label>
                        </div>
                        <LinearProgress
                          color="inherit"
                          className={classes.barSeven}
                          value={adminData && adminData.overAllData.onBreak}
                          variant="determinate"
                        />
                      </Box>
                      <Box
                        sx={{ width: "100%", marginTop: 3, marginBottom: 3 }}
                      >
                        <div className={classes.divStyle2}>
                          <Typography gutterBottom>Off Programme</Typography>
                          <label>
                            {adminData && adminData.overAllData.offProgramme}%
                          </label>
                        </div>
                        <LinearProgress
                          color="inherit"
                          className={classes.barSix}
                          value={
                            adminData && adminData.overAllData.offProgramme
                          }
                          variant="determinate"
                        />
                      </Box>
                      <Box
                        sx={{ width: "100%", marginTop: 3, marginBottom: 3 }}
                      >
                        <div className={classes.divStyle2}>
                          <Typography gutterBottom>Completed</Typography>
                          <label>
                            {adminData && adminData.overAllData.completed}%
                          </label>
                        </div>
                        <LinearProgress
                          color="inherit"
                          className={classes.barTwo}
                          value={adminData && adminData.overAllData.completed}
                          variant="determinate"
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              ) : (
                <div className={classes.skeleton}>
                  <Skeleton variant="text" height={50} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                </div>
              )}

              {isDesktop && (
                <div className="adminSubscriptionBox">
                  <SubscriptionInfo message={subscriptionInfoMessage} />
                </div>
              )}

              {!isDesktop && (
                <div className="adminSubscriptionBox">
                  <SubscriptionInfo message={subscriptionInfoMessage} />
                </div>
              )}
            </Container>
          </Grid>

          {/* {adminData && (
            <Container className="orderLast" maxWidth="xl">
              <Charts data={adminData.graphData} />
            </Container>
          )} */}
        </Grid>
        <Container maxWidth="xl">
          {!isDesktop && data && data._id === id && (
            <HelpAndSupport message={helpAndSupportMessage} />
          )}
        </Container>
      </Container>

      {/* Delete User Confirmation Modal */}
      <ConfirmationModal
        open={confirmationModal}
        close={closeConfirmationModal}
        title="Confirmation"
        text={`Are you sure you want to delete this ${entity}?`}
        ctaTitleText="Delete"
        cta={entity === "learner" ? deleteLearner : deleteFaculty}
      />

      {/* Create New Learner  */}
      <AddLearner
        openAddLearner={openAddLearner}
        toggleAddNewLearner={() => setOpenAddLearner(!openAddLearner)}
      />

      {/* Create New Faculty Modal */}
      <AddFaculty
        open={openAddFacultyModal}
        close={() => setOpenAddFacultyModal(false)}
        facultyRoles={facultyRoles}
        setSuperAdminAlertModal={setSuperAdminAlertModal}
        newFacultyValidations={newFacultyValidations}
      />

      {/* Upgrade Plan Modal */}
      <UpgradePlanModal
        open={openUpgradePlanModal}
        close={() => setopenUpgradePlanModal(false)}
        title="Update your plan"
        text="Please update your subscription plan to use this feature."
      />

      {/* Edit/ Update Faculty Modal */}
      <UpdateFaculty
        open={openUpdateFacultyModal}
        close={() => setOpenUpdateFacultyModal(false)}
        tempFaculty={tempFaculty}
        setTempFaculty={setTempFaculty}
        facultyRoles={facultyRoles}
        setSuperAdminAlertModal={setSuperAdminAlertModal}
        newFacultyValidations={newFacultyValidations}
      />

      {/* Edit/ Update Learner Modal */}
      <UpdateLearner
        open={openUpdateLearnerModal}
        close={() => setOpenUpdateLearnerModal(false)}
        tempLearner={tempLearner}
        setTempLearner={setTempLearner}
        newLearnerValidations={newLearnerValidations}
      />

      {/* Super Admin Alert Modal */}
      <Modal
        open={superAdminAlertModal}
        onClose={() => setSuperAdminAlertModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} className="modalContainer">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="modalHeader"
          >
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h3"
              component="h2"
            >
              Confirmation
            </Typography>
            <CloseIcon
              className={classes.icons}
              title="Close"
              onClick={() => setSuperAdminAlertModal(false)}
            />
          </Grid>
          <p>
            Note: You are going to create a Super Admin which will transfer all
            your super powers to new Super Admin.
          </p>
          <div className="modalFooter">
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button
                onClick={() => setSuperAdminAlertModal(false)}
                className={classes.button}
                style={{ marginRight: "1rem" }}
              >
                Close
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
};
