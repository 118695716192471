import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import jwt from "jwt-decode";
import { AppContext } from "../context/appContext";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function PrivateRoute({ roles, component: Component, ...rest }) {
  let decodedToken;
  const { data } = useContext(AppContext);

  const isAuthenticated = (props) => {
    let token = localStorage.getItem("7-star-training");

    if (token) {
      decodedToken = jwt(token.replace("Bearer ", "")); // decode your token here
      return true;
    }

    sessionStorage.setItem("loginRedirect", window.location.href);
    return false;
  };

  // console.log(data);
  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated(props) ? (
          data ? (
            data.subscription.status != "active" ? (
              <Redirect to="/pricing" />
            ) : roles.includes(decodedToken.role) ? (
              <Component {...props} />
            ) : (
              <Redirect to="/" />
            )
          ) : (
            <Backdrop style={{ zIndex: 10000 }} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
}

export default PrivateRoute;
