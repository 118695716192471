import React, { useEffect, useState, useContext, Fragment } from "react";
import "./SubscriptionInfo.css";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormGroup,
  Modal,
  Typography,
  Skeleton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppContext } from "../../context/appContext";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import GetAppIcon from "@mui/icons-material/GetApp";
import TimeAgo from "react-timeago";
// import Skeleton from '@mui/lab/Skeleton';
import jwt from "jwt-decode";
import { useHistory } from "react-router-dom";
import { capitalCase } from "capital-case";

const useStyles = makeStyles(() => ({
  cardStyle: {
    marginTop: 20,
    borderRadius: 4,
  },
  button: {
    background: "#5335CA",
    "&:hover": {
      background: "#5335CA",
    },
  },
  buttonn: {
    background: "#939393",
    "&:hover": {
      background: "#939393",
    },
    marginRight: 10,
    marginBottom: 10,
  },
  textArea: {
    outline: "none",
    minWidth: 500,
    maxWidth: "100%",
    overflow: "auto !important",
    maxHeight: 300,
    padding: 10,
    resize: "none",
    marginBottom: 20,
    borderRadius: 4,
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
    cursor: "pointer",
    zIndex: 10,
  },
  thedivstyle: {
    marginTop: "10",
  },
  skeleton: {
    backgroundColor: "#fff",
    padding: "20px",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 4,
    marginTop: 20,
  },
}));

const stylee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "95vw",
  maxHeight: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const SubscriptionInfo = ({ message }) => {
  // data, setData, handleToggle, updateMessage, getUserData
  const { data, handleToggle, updateMessage } = useContext(AppContext);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [showSubscription, setShowSubscription] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const history = useHistory();
  const classes = useStyles();

  // To Get subscription Data
  const fetchSubscriptionData = async () => {
    let token = localStorage.getItem("7-star-training");
    let decodedToken = jwt(token.replace("Bearer ", "")); // decode your token here
    // console.log(data);
    if (data.tenant.subscription.plan == "custom") {
      try {
        handleToggle(true);
        await axios
          .get("faculty/admin/subscription", {
            config: { handlerEnabled: true },
          })
          .then((response) => {
            setSubscriptionData(response.data.data);
            setShowSubscription(!showSubscription);
            handleToggle(false);
          });
      } catch (e) {
        handleToggle(false);
        if (e.response && e.response.data) {
          updateMessage(e.response.data.message);
        }
      }
    } else {
      try {
        handleToggle(true);
        await axios
          .post(
            "saas/billing",
            { company: decodedToken.company },
            {
              config: { handlerEnabled: true },
            }
          )
          .then((response) => {
            window.location.href = response.data.data.url;
            // setSubscriptionData(response.data.data);
            // setShowSubscription(!showSubscription);
            // handleToggle(false);
          });
      } catch (e) {
        handleToggle(false);
        if (e.response && e.response.data) {
          updateMessage(e.response.data.message);
        }
      }
    }
  };

  const columns = [
    { field: "id", headerName: "No.", sortable: false, width: 55 },
    {
      field: "cardHolderName",
      headerName: "Card Holder",
      sortable: false,
      width: 180,
    },
    { field: "status", headerName: "Status", sortable: false },
    { field: "amount", headerName: "Amount", sortable: false },
    {
      field: "timeStamp",
      headerName: "TimeStamp",
      sortable: false,
      renderCell: (params) => {
        return (
          <TimeAgo
            date={new Date(params.row.timeStamp * 1000)}
            title={new Date(params.row.timeStamp * 1000).toDateString()}
          />
        );
      },
    },
    {
      field: "receiptUrl",
      headerName: "Receipt",
      sortable: false,
      renderCell: (params) => {
        return (
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <a
                href={params.row.receiptUrl}
                target="_blank"
                rel="noreferrer"
                aria-label="Download Receipt"
              >
                <GetAppIcon fontSize="small" className={classes.icons} />
              </a>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const rows = [];

  useEffect(() => {
    subscriptionData &&
      subscriptionData.paymentRecords &&
      subscriptionData.paymentRecords.map((paymentRecord, index) => {
        rows.push({
          id: index + 1,
          cardHolderName: paymentRecord.cardHolderName,
          status: paymentRecord.status,
          amount: `£${paymentRecord.amount}`,
          timeStamp: paymentRecord.timestamp,
          receiptUrl: paymentRecord.receiptUrl,
        });

        return "";
      });
    setRowsData(rows);
  }, [subscriptionData]); // eslint-disable-line react-hooks/exhaustive-deps

  // To Redirect to Stripe For payment
  const redirectToStripe = async () => {
    try {
      handleToggle(true);
      await axios
        .get("faculty/stripe/session", {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          handleToggle(false);
          window.location.replace(response.data.data.url);
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  function CustomNoRowsOverlay() {
    const classes = useStyles();

    return (
      <GridOverlay>
        <div className={classes.noDataImage}>
          <img src="/no-data-found.png" alt="" />
        </div>
      </GridOverlay>
    );
  }

  return (
    <Fragment>
      <Fragment>
        {data ? (
          <Box className={classes.cardStyle}>
            <Card>
              <CardContent className="card-content">
                <Typography variant="h5" component="h3" gutterBottom>
                  Subscriptions
                </Typography>
                <Typography variant="body2">{message}</Typography>
                
                <div className="bottom-part">
                  <Typography
                    variant="h5" component="h3"
                    fontSize="1rem"
                    marginTop="8px"
                    gutterBottom>
                    Current Plan:
                  </Typography>
                  <Typography variant="body2" marginTop="8px" >{capitalCase(data.tenant.subscription.plan)}</Typography>
                </div>
                <div className="bottom-part-2">
                  <Typography variant="h5" component="h3" fontSize="1rem" marginTop="8px" gutterBottom>
                    Quantity:
                  </Typography>
                  <Typography variant="body2" marginTop="8px">{data.tenant.subscription.total}</Typography>
                </div>
                <CardActions
                  sx={{ padding: 0, paddingTop: 1.2, paddingBottom: 1.2 }}
                >
                  <Button
                    variant="contained"
                    endIcon={<SendIcon size="small" />}
                    className={classes.button}
                    onClick={fetchSubscriptionData}
                    // onClick={() => history.push("/upgrade-subscription")}
                  >
                    Subscription Info
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          </Box>
        ) : (
          <div className={classes.skeleton}>
            <Skeleton variant="text" height={45} />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" width="70%" />
            <Skeleton variant="text" width="70%" height={45} />
          </div>
        )}
      </Fragment>

      {/* Subscription Info Modal */}
      <Modal
        open={showSubscription}
        onClose={() => setShowSubscription(!showSubscription)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <Box sx={stylee} className="modalContainer subscriptionModalContainer">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="modalHeader"
          >
            <Typography variant="h5" component="h3" component="h2">
              Subscriptions {subscriptionData.length && subscriptionData}
            </Typography>
            <CloseIcon
              className={classes.icons}
              title="Close"
              onClick={() => setShowSubscription(!showSubscription)}
            />
          </Grid>

          <Grid container>
            <Typography component="h3" className="subscription__subHeading">
              Payment Records
            </Typography>
            <div className="dataGrid__wrapper">
              <DataGrid
                rows={rowsData}
                columns={columns}
                pageSize={3}
                rowsPerPageOptions={[3]}
                disableColumnMenu
                disableSelectionOnClick
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            </div>
          </Grid>

          <FormGroup
            sx={{ paddingTop: 3 }}
            className="modalFooter subscriptionModalFooter"
          >
            <Grid container>
              <Grid item xs={6} md={3}>
                <Typography component="h3" className="subscription__subTitle">
                  Used: <span>{subscriptionData?.subscriptionInfo?.used}</span>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography component="h3" className="subscription__subTitle">
                  Total:{" "}
                  <span>{subscriptionData?.subscriptionInfo?.total}</span>
                </Typography>
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
              className="subscription__btn"
            >
              <Button
                variant="contained"
                className={classes.button}
                onClick={redirectToStripe}
                title="Add Subscription"
              >
                Add
              </Button>
              <Button
                variant="contained"
                className={classes.buttonn}
                onClick={() => setShowSubscription(!showSubscription)}
              >
                Cancel
              </Button>
            </div>
          </FormGroup>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default SubscriptionInfo;
