import React from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { Stack } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      width: "18ch",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  },
  timeInputContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      gap: "1rem",
      flexDirection: "column",
    },
  },
}));

export default function TimeInput(props) {
  const classes = useStyles();

  const hoursChangeHandler = (e) => {
    props.setLearningActivity({
      ...props.learningActivity,
      hours: e.target.value,
    });
    if (e.target.value < 0) {
      props.setError({
        ...props.error,
        hoursError: "Please enter valid hours",
      });
    } else if (e.target.value > 0) {
      props.setError({ ...props.error, hoursError: false });
    }
  };

  const minsChangeHandler = (e) => {
    props.setLearningActivity({
      ...props.learningActivity,
      minutes: e.target.value,
    });
    if (e.target.value < 0 || e.target.value > 59) {
      props.setError({
        ...props.error,
        minutesError: "Please enter valid minutes",
      });
    } else if (e.target.value > 0) {
      props.setError({ ...props.error, minutesError: false });
    }
  };
  const keyPress = (event) => {
    if (event.key === ".") {
      event.preventDefault();
    }
  };
  const keyInput = (event) => {
    event.target.value = event.target.value.replace(/[^0-9]*/g, "");
  };

  return (
    <form noValidate autoComplete="off">
      <Stack
        sx={{
          "& .MuiTextField-root": {
            width: { sm: "100%", md: "100%" },
          },
          gap: "1rem",
        }}
      >
        <TextField
          id="standard-number"
          label="Hours"
          type="number"
          onChange={hoursChangeHandler}
          value={props.learningActivity.hours}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ inputProps: { min: 0 } }}
          error={props.error.hoursError}
          helperText={props.error.hoursError}
          onKeyDown={keyPress}
          onInput={keyInput}
        />
        <TextField
          id="standard-number"
          label="Minutes"
          type="number"
          onChange={minsChangeHandler}
          value={props.learningActivity.minutes}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ inputProps: { min: 0, max: 59 } }}
          error={props.error.minutesError}
          helperText={props.error.minutesError}
          onKeyDown={keyPress}
          onInput={keyInput}
        />
      </Stack>
    </form>
  );
}
