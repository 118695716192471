export const _customBasicPricingPlan = {
  price: "price_1KibUzDIf5MKeP8OmjHPzuq8",
  name: "basic",
  lists: [
    { text: "All essential features", isAvailable: true },
    // { text: "5 GB Data Storage", isAvailable: true },
    {
      text: "7 Star Training Support (Working Days Only)",
      isAvailable: true,
    },
    {
      text: "OTJ Evidence Report",
      isAvailable: false,
    },
    {
      text: "Customisable Off-The-Job Hours",
      isAvailable: false,
    },
  ],
};

export const _customStandardPricingPlan = {
  price: "price_1KibUzDIf5MKeP8OmjHPzuq8",
  name: "standard",
  lists: [
    { text: "All essential features", isAvailable: true },
    // { text: "20 GB Data Storage", isAvailable: true },
    {
      text: "7 Star Training Support (Working Days Only)",
      isAvailable: true,
    },
    {
      text: "OTJ Evidence Report",
      isAvailable: true,
    },
    {
      text: "Customisable Off-The-Job Hours",
      isAvailable: true,
    },
  ],
};

export const _customPricingPlan = {
  price: "price_1KibUzDIf5MKeP8OmjHPzuq8",
  name: "custom",
  lists: [
    { text: "Non recuring, pay once", isAvailable: true },
    { text: "Customizable Subscriptions", isAvailable: true },
    { text: "Unlimited Storage", isAvailable: true },
    {
      text: "Organisation Support + 7 Star Training Support (24/7)",
      isAvailable: true,
    },
  ],
};
