import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Modal, Button, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "lightgray",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 4,
  },
  icons: {
    cursor: "pointer",
  },
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
  buttonn: {
    background: "#939393",
    color: "white",
    marginRight: 10,
    "&:hover": {
      background: "#888888",
    },
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 550,
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: 2,
  p: 4,
};

export default function ConfirmationModal({ open, close, title, text, ctaTitleText, cta }) {
  const classes = useStyles();

  
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle} className="modalContainer">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="modalHeader"
        >
          <Typography id="modal-modal-title" variant="h5" component="h3" component="h2">
            {title}
          </Typography>
          <CloseIcon
            className={classes.icons}
            title="Close"
            onClick={close}
          />
        </Grid>
        <p>{text}</p>
 {       <div className="modalFooter">
          <div
            style={{
              // paddingTop: "10px",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Button onClick={cta} className={classes.button}>
              {ctaTitleText}
            </Button>
            <Button onClick={close} className={classes.buttonn} style={{ marginRight: '1rem' }}>
              Cancel
            </Button>
          </div>
        </div>}
      </Box>
    </Modal>
  );
}
