import React, { useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Box,
} from "@mui/material";
import PricingPlanCard from "./PricingPlanCard";

const ChoosePlane = ({ pricing }) => {
  const [quantity, setQuantity] = useState(10);
  const [selectedPlan, setSelectedPlan] = useState("yearly");

  const handleRadioChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  return (
    <Container maxWidth="xl">
      <Stack mb={8} direction="column">
        <Typography
          fontWeight={"bold"}
          variant="h4"
          align="center"
          paragraph
          gutterBottom
        >
          Subscription Plans for Your
          <br /> Off-The-Job Training Web App
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <RadioGroup row value={selectedPlan} onChange={handleRadioChange}>
            <FormControlLabel
              value="monthly"
              control={<Radio color="primary" />}
              label="Monthly Plan"
            />
            <FormControlLabel
              value="yearly"
              control={<Radio color="primary" />}
              label="Yearly Plan"
            />
          </RadioGroup>
          <Box sx={{ pt: 0.5 }}>
            <Chip sx={{ color: "#008000" }} label="Save up to 40%" />
          </Box>
        </Stack>
      </Stack>

      {selectedPlan === "yearly" && (
        <Grid
          container
          spacing={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {pricing?.data?.yearlyPlans?.map((card, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={card.id}
              display="flex"
              justifyContent="center"
            >
              <PricingPlanCard card={card} index={index} quantity={quantity} />
            </Grid>
          ))}
        </Grid>
      )}

      {selectedPlan === "monthly" && (
        <Grid
          container
          spacing={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {pricing?.data?.monthlyPlans?.map((card, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={card.id}
              display="flex"
              justifyContent="center"
            >
              <PricingPlanCard card={card} index={index} quantity={quantity} />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default ChoosePlane;
