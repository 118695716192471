import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Modal, Button, Box, Typography } from "@mui/material";
// import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
// import Fade from "@mui/material/Fade";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "lightgray",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 4,
  },
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
  icons: {
    cursor: "pointer",
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 550,
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: 2,
  p: 4,
};

export default function ApprentiseshipStatusModal(props) {
  const classes = useStyles();
  //   const [open, setOpen] = React.useState(true);

  return (
    <Modal
      open={props.open}
      onClose={props.close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle} className="modalContainer">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="modalHeader"
        >
          <Typography id="modal-modal-title" variant="h5" component="h3">
            Alert
          </Typography>
          <CloseIcon
            className={classes.icons}
            title="Close"
            onClick={props.close}
          />
        </Grid>
        <p>{props.ErrorMessage}</p>
        <div className="modalFooter">
          <div
            style={{
              // paddingTop: "10px",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Button onClick={props.close} className={classes.button}>
              Close
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
