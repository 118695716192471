import React, { useState, useEffect, useContext, Fragment } from "react";
import { useHistory } from "react-router-dom";
import "./AddLearner.css";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import axios from "axios";
import moment from "moment";
import { cloneDeep, isEmpty } from "lodash";
import { AppContext } from "../../context/appContext";
import { makeStyles } from "@mui/styles";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import ClearIcon from "@mui/icons-material/Clear";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Link from "@mui/material/Link";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import enLocale from "date-fns/locale/en-GB";
import {
  formatMinutesInOOOO,
  formatSecondsInOOOO,
  getChangeAbleIndex,
  getDaysBetweenTwoDates,
  getPlanHoursMinutes,
  getWeeksBetweenTwoDates,
  validateEmail,
} from "../../utils/functions";
import jwt from "jwt-decode";
import {
  Alert,
  Card,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { getLearners, setAllLearners } from "../../redux/slices/learner";
import { useDispatch, useSelector } from "../../redux/store";
import calculateLearnerHours from "../../utils/calculateLearnerHours";
import { useParams } from "react-router-dom";
import autoCalculateDurations from "../../utils/autoCalculateDurations";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const useStyles = makeStyles(() => ({
  divStyle: {
    display: "flex",
  },
  divStyle2: {
    display: "flex",
    justifyContent: "space-between",
  },

  field: {
    marginBottom: 13,
  },
  boxStyle: {
    marginTop: 20,
    padding: 40,
    borderRadius: 4,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    "@media (max-width: 768px)": {
      padding: 20,
    },
  },
  cardStyle: {
    marginTop: 20,
    borderRadius: 4,
  },
  profileCard: {
    background: "#5335CA",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 4,
  },
  profileCardText: {
    color: "white",
    fontSize: 16,
  },
  profileCardText1: {
    color: "white",
    fontSize: 12,
  },
  text1: {
    marginTop: "50px",
    marginLeft: "25px",
  },
  text2: {
    marginTop: "30px",
    // marginLeft: "25px",
  },
  text1Sub: {
    fontSize: "1.1rem",
  },
  typoText1: {
    fontSize: 9,
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
    cursor: "pointer",
    zIndex: 10,
  },
  qIcon: {
    height: "22.58px",
    top: "6px",
    width: "20px",
    position: "relative",
  },
  button: {
    background: "#5335CA",
    "&:hover": {
      background: "#5335CA",
    },
  },
  buttonn: {
    background: "#939393",
    "&:hover": {
      background: "#888888",
    },
    marginRight: 10,
  },
  form: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  label: {
    padding: "inherit",
  },
  textArea: {
    outline: "none",
    minWidth: 500,
    maxWidth: "100%",
    // overflow: "auto !important",
    maxHeight: 300,
    padding: 10,
    resize: "none",
    marginBottom: 20,
    borderRadius: 4,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
}));

// const steps = ["Personal Info", "Apprenticeship Info", "Training Hours"];
const steps = ["Personal Info", "Apprenticeship Info"];

const AddLearnerPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const history = useHistory();

  const classes = useStyles();
  // data, setData, handleToggle, updateMessage, getUserData
  const { setData, data, handleToggle, updateMessage } = useContext(AppContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { allFaculties } = useSelector((state) => state.faculty);
  const [filteredFaculties, setFilteredFaculties] = useState({
    coach: [],
    manager: [],
    mentor: [],
    visitor: [],
    trainer: [],
  });

  /* VARIABLES */
  const newLearnerInitial = {
    uln: "",
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    trainingProviderName: "",
    coachMentor: [],
    coach: "",
    manager: "",
    mentor: "",
    trainer: "",
    employerName: "",
    apprenticeshipStandard: "",
    startDate: new Date(),
    endDate: new Date(),
    durationInHours: "",
    totalMinutes: 0,
    emailToMentor: true,
    emailToCoach: true,
    monthlyHourMinutes: [],
    monthlyHourMinutesUpdated: [],
  };
  const [agree, setAgree] = useState(false);

  const handleAgreeChange = (event) => {
    setAgree(event.target.checked);
  };
  const newLearnerValidations = {
    // uln: false,
    name: false,
    email: false,
    password: false,
    confirmPassword: false,
    // trainingProviderName: false,
    // employerName: false,
    // apprenticeshipStandard: false,
    startDate: false,
    endDate: false,
    // durationInMonths: false,
    durationInHours: false,
    // durationInWeeks: false,
    // trainingPercentage: false,
    // minHoursPerWeek: false,
    // targetMonths: false,
  };

  /* STATES */
  // For New Learner Modal
  const [newLearner, setNewLearner] = useState(newLearnerInitial);
  const [learnerValidations, setLearnerValidations] = useState(
    newLearnerValidations
  );
  const [disableReset, setDisableReset] = useState(true);

  // For Reset Modal
  const [resetModal, setResetModal] = useState(false);
  const [changedSeconds, setChangedSeconds] = useState(0);

  // For Subscription Redirect Modal
  const [redirectSubscriptionModal, setRedirectSubscriptionModal] =
    useState(false);

  /* FUNCTIONS */
  const newLearnerChangeHandler = (e, selectInput) => {
    if (e && e.target && e.target.name) {
      let { name, value } = e.target;

      if (name === "durationInHours") {
        // For durationInHours, set it as a blank string if value is empty; otherwise, convert to Number
        setNewLearner({
          ...newLearner,
          [name]: value === "" ? "" : Number(value),
        });

        // Adjusting validation for durationInHours
        if (value || value === "") {
          setLearnerValidations({
            ...learnerValidations,
            [name]: !value, // Assuming validation is true when there is no value
          });
        }
      } else if (name === "password" || name === "confirmPassword") {
        setNewLearner({
          ...newLearner,
          [name]: value,
        });

        if (value) {
          setLearnerValidations({
            ...learnerValidations,
            [name]: false,
          });
        }
        if (name === "password") {
          if (
            value &&
            newLearner.confirmPassword &&
            value === newLearner.confirmPassword
          ) {
            setLearnerValidations({
              ...learnerValidations,
              password: false,
              confirmPassword: false,
            });
          }
        } else {
          if (newLearner.password && value && newLearner.password === value) {
            setLearnerValidations({
              ...learnerValidations,
              password: false,
              confirmPassword: false,
            });
          }
        }
      } else {
        // For all other fields, keep the original logic
        setNewLearner({
          ...newLearner,
          [name]: value,
        });

        if (value) {
          setLearnerValidations({
            ...learnerValidations,
            [name]: false,
          });
        }
      }
    } else if (
      e &&
      (selectInput === "startDate" || selectInput === "endDate")
    ) {
      setNewLearner({
        ...newLearner,
        [selectInput]: e.$d,
      });
      if (e) {
        setLearnerValidations({
          ...learnerValidations,
          [selectInput]: false,
        });
      }
    } else if (
      e &&
      (selectInput === "coach" ||
        selectInput === "manager" ||
        selectInput === "mentor" ||
        selectInput === "trainer")
    ) {
      setNewLearner({
        ...newLearner,
        [selectInput]: e,
      });
    }
  };

  // FormHandler For Add New Learner Modal CheckBoxes
  const newLearnerCheckboxHandler = (e) => {
    let { name } = e.target;

    setNewLearner({
      ...newLearner,
      [name]: !newLearner[name],
    });
  };

  // FormHandler For Reset Learner Durations Modal
  const handleDurationChangeMinutes = (e, index) => {
    let { name, value } = e.target;
    let cloneDurations = cloneDeep(newLearner.monthlyHourMinutesUpdated);

    if (name === "hours") {
      // cloneDurations[index].targetHours = Number(value);
      if (value >= 0) {
        cloneDurations[index].targetHours = Number(value);
      } else {
        updateMessage("Hours can not be less than 0");
        return;
      }
    } else {
      if (value >= 0 && value < 60) {
        cloneDurations[index].targetMinutes = Number(value);
      } else {
        updateMessage("Minutes can not be greater than 59 or less than 0");
        return;
      }
    }

    // console.log(cloneDurations);
    setNewLearner({
      ...newLearner,
      monthlyHourMinutesUpdated: cloneDurations,
    });

    // let newCloneDuration = autoCalculateDurations(
    //   index,
    //   cloneDurations,
    //   newLearner
    // );

    // console.log(newCloneDuration);

    // if (newCloneDuration.error) {
    //   updateMessage(newCloneDuration.error);
    // } else {
    //   setNewLearner({
    //     ...newLearner,
    //     monthlyHourMinutesUpdated: newCloneDuration.cloneDuration,
    //   });
    // }
  };

  const handleDurationChangeHours = (e, index) => {
    let { name, value } = e.target;
    let cloneDurations = cloneDeep(newLearner.monthlyHourMinutesUpdated);

    if (name === "hours") {
      // Check if the value is an empty string or a valid number
      if (value === "") {
        cloneDurations[index].targetHours = "";
      } else if (/^\d+$/.test(value) && parseInt(value, 10) >= 0) {
        // Only convert to number and assign if it's a valid positive integer
        cloneDurations[index].targetHours = parseInt(value, 10);
      } else {
        updateMessage("Hours must be a positive number or empty.");
        return;
      }
    } else {
      // Assuming this else branch is for "minutes" or similar
      if (
        /^\d+$/.test(value) &&
        parseInt(value, 10) >= 0 &&
        parseInt(value, 10) < 60
      ) {
        cloneDurations[index].targetMinutes = parseInt(value, 10);
      } else {
        updateMessage("Minutes must be between 0 and 59.");
        return;
      }
    }

    setNewLearner({
      ...newLearner,
      monthlyHourMinutesUpdated: cloneDurations,
    });
  };

  // To calculate the no. of days between two dates
  const daysBetween = (first, second) => {
    // Copy date parts of the timestamps, discarding the time parts.
    var one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
    var two = new Date(
      second.getFullYear(),
      second.getMonth(),
      second.getDate()
    );

    // Do the math.
    var millisecondsPerDay = 1000 * 60 * 60 * 24;
    var millisBetween = two.getTime() - one.getTime();
    var days = millisBetween / millisecondsPerDay;

    // Round down.
    return Math.floor(days);
  };

  // To Reset The Add New Learner Modal
  const resetAddNewLearner = () => {
    setNewLearner(newLearnerInitial);
    setLearnerValidations(newLearnerValidations);
  };

  // To Open The Reset Durations Modal
  const toggleResetDurations = () => {
    setResetModal(!resetModal);
  };

  // To Add A New Learner
  const addNewLearner = async () => {
    // 1- Validations
    if (isSecondStepInvalid()) {
      if (newLearner.name === "") {
        setLearnerValidations({ ...learnerValidations, name: true });
        return;
      }
      if (newLearner.email === "" || !validateEmail(newLearner.email)) {
        setLearnerValidations({ ...learnerValidations, email: true });
        return;
      }

      // Add more validation checks as needed

      if (newLearner.startDate === "") {
        setLearnerValidations({ ...learnerValidations, startDate: true });
        return;
      }
      if (
        newLearner.endDate === "" ||
        moment(newLearner.endDate).isBefore(newLearner.startDate)
      ) {
        setLearnerValidations({ ...learnerValidations, endDate: true });
        return;
      }

      if (newLearner.durationInHours === "") {
        setLearnerValidations({ ...learnerValidations, durationInHours: true });
        return;
      }

      if (newLearner.minHoursPerWeek === "") {
        setLearnerValidations({
          ...learnerValidations,
          minHoursPerWeek: true,
        });
        return;
      }

      if (newLearner.targetMonths === "") {
        setLearnerValidations({
          ...learnerValidations,
          targetMonths: true,
        });
        return;
      }

      if (newLearner.monthlyHourMinutes.length === 0) {
        updateMessage("Please make sure the start and dates are correct");
        return;
      }

      // 2- Calculations
      let coachMentor = allFaculties.filter(
        (faculty) =>
          faculty.email === newLearner.coach ||
          faculty.email === newLearner.manager ||
          faculty.email === newLearner.mentor ||
          faculty.email === newLearner.trainer
      );

      // 3- Api
      try {
        let updatedLearner = {
          ...cloneDeep(newLearner),
          startDate: newLearner.startDate.toLocaleDateString("en-GB", {
            timeZone: "Europe/London",
          }),
          endDate: newLearner.endDate.toLocaleDateString("en-GB", {
            timeZone: "Europe/London",
          }),
          coachMentor,
          monthlyHourMinutes: newLearner.monthlyHourMinutesUpdated,
        };

        handleToggle(true);
        await axios
          .post("learner/register", updatedLearner, {
            config: { handlerEnabled: true },
          })
          .then((response) => {
            handleToggle(false);
            localStorage.setItem("7-star-training", response.data.token);
            localStorage.setItem("isFirstLogin", "true"); // Set flag on signup
            setData(response.data.data);

            history.push(
              `/${response.data.data.role}/${response.data.data._id}`
            );
          });
      } catch (e) {
        handleToggle(false);

        if (e.response && e.response.status === 403) {
          setRedirectSubscriptionModal(!redirectSubscriptionModal);
        } else if (e.response && e.response.data) {
          updateMessage(e.response.data.message);
        }
      }
    }
  };

  // console.log("new", newLearner);
  // To Calculate The Changed Durations Of New Learner
  useEffect(() => {
    if (
      newLearner &&
      newLearner.monthlyHourMinutesUpdated &&
      newLearner.monthlyHourMinutesUpdated.length > 0
    ) {
      let changedHours = newLearner.monthlyHourMinutesUpdated.map(
        (x) => x.targetHours
      );
      let changedMinutes = newLearner.monthlyHourMinutesUpdated.map(
        (x) => x.targetMinutes
      );

      let changedSeconds;
      changedHours = changedHours.reduce((a, b) => a + b, 0);
      changedMinutes = changedMinutes.reduce((a, b) => a + b, 0);

      changedSeconds = Math.floor(changedHours * 3600 + changedMinutes * 60);

      setChangedSeconds(changedSeconds);
    }
  }, [newLearner]); // eslint-disable-line react-hooks/exhaustive-deps

  // To Enable/ Disable Reset Durations Modal Button
  useEffect(() => {
    if (
      newLearner.startDate &&
      newLearner.endDate &&
      newLearner.durationInHours
      // newLearner.trainingPercentage
    ) {
      calculateLearnerHoursHandler();
      setDisableReset(false);
    } else {
      setDisableReset(true);
    }
  }, [newLearner.startDate, newLearner.endDate, newLearner.durationInHours]); // eslint-disable-line react-hooks/exhaustive-deps

  // To Set/ Update The Filtered Faculties When 'faculties' State Changes
  useEffect(() => {
    if (allFaculties && allFaculties.length > 0) {
      let coach = [],
        manager = [],
        mentor = [],
        visitor = [],
        trainer = [];

      for (let faculty of allFaculties) {
        if (faculty?.role === "coach") {
          coach.push(faculty);
        } else if (faculty?.role === "manager") {
          manager.push(faculty);
        } else if (faculty?.role === "mentor") {
          mentor.push(faculty);
        } else if (faculty?.role === "visitor") {
          visitor.push(faculty);
        } else if (faculty?.role === "trainer") {
          trainer.push(faculty);
        }
      }
      setFilteredFaculties({
        coach,
        manager,
        mentor,
        visitor,
        trainer,
      });
    }
  }, [allFaculties]);

  const updateHoursAndMinutes = () => {
    if (changedSeconds / 60 === newLearner.totalMinutes) {
      return true;
    } else {
      updateMessage("TOTAL TIME and UPDATED TIME should be equal");
      return false;
    }
  };

  // To Redirect to Stripe For payment
  const redirectToStripe = async () => {
    let token = localStorage.getItem("7-star-training");
    let decodedToken = jwt(token.replace("Bearer ", "")); // decode your token here
    if (data.tenant.subscription.plan == "custom") {
      try {
        handleToggle(true);
        await axios
          .get("faculty/stripe/session", {
            config: { handlerEnabled: true },
          })
          .then((response) => {
            handleToggle(false);
            // window.location.replace(response.data.data.url);
            // window.open(response.data.data.url, "_blank");
            window.location.href = response.data.data.url;

            setRedirectSubscriptionModal(!redirectSubscriptionModal);
          });
      } catch (e) {
        handleToggle(false);
        if (e.response && e.response.data) {
          updateMessage(e.response.data.message);
        }
      }
    } else {
      try {
        handleToggle(true);
        await axios
          .post(
            "saas/billing",
            { company: decodedToken.company },
            {
              config: { handlerEnabled: true },
            }
          )
          .then((response) => {
            handleToggle(false);
            // window.location.href = response.data.data.url;
            // window.open(response.data.data.url, "_blank");
            window.location.href = response.data.data.url;

            setRedirectSubscriptionModal(!redirectSubscriptionModal);
            // setSubscriptionData(response.data.data);
            // setShowSubscription(!showSubscription);
            // handleToggle(false);
          });
      } catch (e) {
        handleToggle(false);
        if (e.response && e.response.data) {
          updateMessage(e.response.data.message);
        }
      }
    }
  };

  const getTimeDifference = () => {
    let seconds =
      Number(changedSeconds) - Number(newLearner?.totalMinutes * 60);
    seconds = Math.abs(seconds);
    // console.log(seconds);
    return formatSecondsInOOOO(seconds);
  };

  const calculateLearnerHoursHandler = () => {
    let learner = calculateLearnerHours(newLearner);
    // console.log("LEARNER", learner);
    setNewLearner(learner);
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      let isValid = isFirstStepInvalid();
      if (!isValid) return;
    }
    if (activeStep === 1) {
      let isValid = isSecondStepInvalid();
      if (!isValid) return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const isFirstStepInvalid = () => {
    let validateObj = {};
    if (newLearner.name === "") {
      validateObj.name = true;
    }
    if (newLearner.email === "" || !validateEmail(newLearner.email)) {
      validateObj.email = true;
    }

    // if (newLearner.password === "") {
    //   validateObj.password = true;
    // }
    // if (newLearner.confirmPassword === "") {
    //   validateObj.confirmPassword = true;
    // }

    if (
      !(
        newLearner.password &&
        newLearner.confirmPassword &&
        newLearner.password === newLearner.confirmPassword
      )
    ) {
      validateObj.password = true;
      validateObj.confirmPassword = true;
    }

    setLearnerValidations({ ...learnerValidations, ...validateObj });
    if (isEmpty(validateObj)) {
      return true;
    } else {
      return false;
    }
  };

  const isSecondStepInvalid = () => {
    let validateObj = {};
    // if (newLearner.trainingProviderName === "") {
    //   validateObj.trainingProviderName = true;
    // }
    // if (newLearner.employerName === "") {
    //   validateObj.employerName = true;
    // }
    // if (newLearner.apprenticeshipStandard === "") {
    //   validateObj.apprenticeshipStandard = true;
    // }
    if (newLearner.startDate === "") {
      validateObj.startDate = true;
    }
    if (
      newLearner.endDate === "" ||
      moment(newLearner.endDate).isBefore(newLearner.startDate)
    ) {
      validateObj.endDate = true;
    }
    if (newLearner.durationInHours === "") {
      validateObj.durationInHours = true;
    }
    setLearnerValidations({ ...learnerValidations, ...validateObj });
    if (isEmpty(validateObj)) {
      return true;
    } else {
      return false;
    }
  };

  // console.log(newLearner);

  return (
    <Fragment>
      {/* Add New Learner Drawer */}

      <Box>
        <FormGroup sx={{ paddingTop: 2 }}>
          {/* <Stack sx={{ justifyContent: "center" }}> */}
          <Box sx={{ width: "100%" }}>
            <Stepper
              activeStep={activeStep}
              sx={{ padding: 0 }}
              alternativeLabel
            >
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          {/* </Stack> */}
          <Box>
            {
              <React.Fragment>
                <Divider sx={{ mt: 1 }} />
                <Box sx={{ mt: 3 }}>
                  {activeStep === 0 && (
                    <>
                      <TextField
                        label="ULN (Unique Learner Number)"
                        variant="outlined"
                        fullWidth
                        name="uln"
                        className={classes.field}
                        value={newLearner.uln}
                        onChange={(e) => newLearnerChangeHandler(e)}
                        error={learnerValidations.uln}
                        helperText={
                          learnerValidations.uln && "This can't be empty."
                        }
                      />

                      <TextField
                        label="Name*"
                        variant="outlined"
                        fullWidth
                        name="name"
                        className={classes.field}
                        value={newLearner.name}
                        onChange={(e) => newLearnerChangeHandler(e)}
                        error={learnerValidations.name}
                        helperText={
                          learnerValidations.name && "This can't be empty."
                        }
                      />

                      <TextField
                        label="Work Email*"
                        variant="outlined"
                        className={classes.field}
                        type="email"
                        name="email"
                        fullWidth
                        value={newLearner.email}
                        onChange={(e) => newLearnerChangeHandler(e)}
                        error={learnerValidations.email}
                        helperText={
                          learnerValidations.email &&
                          "This must be in email format."
                        }
                      />

                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="Password"
                        name="password"
                        autoComplete="password"
                        // autoFocus
                        value={newLearner.password}
                        type="password"
                        onChange={(e) => newLearnerChangeHandler(e)}
                        // error={learnerValidations.passwordError === "" ? false : true}
                        // helperText={learnerValidations.passwordError}
                        error={learnerValidations.password}
                        helperText={
                          learnerValidations.password &&
                          "Password and Confirm Password should be same."
                        }
                      />

                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="confirmPassword"
                        label="Confirm Password"
                        name="confirmPassword"
                        autoComplete="confirmPassword"
                        type="password"
                        value={newLearner.confirmPassword}
                        onChange={(e) => newLearnerChangeHandler(e)}
                        error={learnerValidations.confirmPassword}
                        helperText={
                          learnerValidations.confirmPassword &&
                          "Password and Confirm Password should be same."
                        }
                        // error={
                        //   learnerValidations.confirmPasswordError === "" ? false : true
                        // }
                        // helperText={learnerValidations.confirmPasswordError}
                      />

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: {
                            xs: "center",
                            sm: "center",
                            md: "space-between",
                          },
                          pt: 2,
                        }}
                      >
                        <Button
                          disableElevation
                          variant="contained"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{
                            mr: 1,
                            color: "white",
                            backgroundColor: "#BDBDBD",
                            "&:hover": {
                              backgroundColor: "#BDBDBD",
                            },
                          }}
                          className={classes.buttonn}
                        >
                          Back
                        </Button>
                        <Box />

                        <Button
                          variant="contained"
                          className={classes.button}
                          onClick={
                            activeStep === 2 ? addNewLearner : handleNext
                          }
                          sx={{
                            backgroundColor: "#5335ca",
                            "&:hover": {
                              backgroundColor: "#4b27dd",
                            },
                          }}
                        >
                          {activeStep === steps.length - 1 ? "Submit" : "Next"}
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
                <Box sx={{ mt: 1 }}>
                  {activeStep === 1 && (
                    <>
                      <TextField
                        label="Training Provider Name"
                        variant="outlined"
                        fullWidth
                        className={classes.field}
                        type="email"
                        name="trainingProviderName"
                        value={newLearner.trainingProviderName}
                        onChange={(e) => newLearnerChangeHandler(e)}
                        error={learnerValidations.trainingProviderName}
                        helperText={
                          learnerValidations.trainingProviderName &&
                          "This can't be empty."
                        }
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "row", sm: "row", md: "column" },
                          gap: { xs: "15px", sm: "15px", md: "0px" },
                        }}
                      >
                        <TextField
                          label="Employer Name"
                          variant="outlined"
                          fullWidth
                          className={classes.field}
                          name="employerName"
                          value={newLearner.employerName}
                          onChange={(e) => newLearnerChangeHandler(e)}
                          error={learnerValidations.employerName}
                          helperText={
                            learnerValidations.employerName &&
                            "This can't be empty."
                          }
                        />

                        <TextField
                          label="Apprenticeship Standard"
                          variant="outlined"
                          fullWidth
                          className={classes.field}
                          name="apprenticeshipStandard"
                          value={newLearner.apprenticeshipStandard}
                          onChange={(e) => newLearnerChangeHandler(e)}
                          error={learnerValidations.apprenticeshipStandard}
                          helperText={
                            learnerValidations.apprenticeshipStandard &&
                            "This can't be empty."
                          }
                        />
                      </Box>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#5A5A5A",
                            pb: "5px",
                            fontWeight: "700",
                          }}
                        >
                          Practical Period:
                        </Typography>
                      </Stack>
                      <Box sx={{ mb: "10px" }}>
                        <Alert className={classes.field} severity="info">
                          Please ensure that the start date, end date, and
                          planned off-the-job training hours are exact as from
                          your individualised learner record (ILR).
                        </Alert>
                      </Box>

                      <Grid container spacing={2} className={classes.field}>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          className="fullWidthInput"
                        >
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            locale={enLocale}
                          >
                            <DatePicker
                              label="Start Date*"
                              name="startDate"
                              value={newLearner.startDate}
                              showToolbar={false}
                              onChange={(e) =>
                                newLearnerChangeHandler(e, "startDate")
                              }
                              error={learnerValidations.startDate}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onKeyDown={(e) => e.preventDefault()}
                                />
                              )}
                              format="DD-MM-YYYY"
                              inputFormat="DD/MM/YYYY"
                            />
                          </LocalizationProvider>
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          className="fullWidthInput"
                        >
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            locale={enLocale}
                          >
                            <DatePicker
                              label=" End Date*"
                              name="endDate"
                              value={newLearner.endDate}
                              showToolbar={false}
                              onChange={(e) =>
                                newLearnerChangeHandler(e, "endDate")
                              }
                              onSelect={(e) =>
                                newLearnerChangeHandler(e, "endDate")
                              }
                              error={learnerValidations.endDate}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onKeyDown={(e) => e.preventDefault()}
                                  error={learnerValidations.endDate}
                                  helperText={
                                    learnerValidations.endDate &&
                                    "End date can't be less than Start date."
                                  }
                                />
                              )}
                              format="DD-MM-YYYY"
                              inputFormat="DD/MM/YYYY"
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>

                      <TextField
                        label="Planned OTJ Training Hours*"
                        variant="outlined"
                        fullWidth
                        className={classes.field}
                        type="number"
                        name="durationInHours"
                        value={newLearner.durationInHours}
                        onChange={(e) => newLearnerChangeHandler(e)}
                        error={learnerValidations.durationInHours}
                        helperText={
                          learnerValidations.durationInHours &&
                          "This can't be empty."
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={agree}
                            onChange={handleAgreeChange}
                            inputProps={{
                              "aria-label":
                                "I agree to the Terms of Use and Privacy Policy",
                            }}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                color: "#5335CA", // Change the color here
                              },
                            }}
                          />
                        }
                        label={
                          <span>
                            I agree with the{" "}
                            <Link
                              href="https://7startraining.co.uk/termsofuse.html"
                              target="_blank"
                              rel="noopener"
                            >
                              Terms of Use
                            </Link>{" "}
                            and{" "}
                            <Link
                              href="https://7startraining.co.uk/privacypolicy.html"
                              target="_blank"
                              rel="noopener"
                            >
                              Privacy Policy
                            </Link>{" "}
                            upon signup.
                          </span>
                        }
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: {
                            xs: "center",
                            sm: "center",
                            md: "space-between",
                          },
                          mb: 3,
                        }}
                      >
                        <Button
                          disableElevation
                          variant="contained"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{
                            mr: 1,
                            color: "white",
                            backgroundColor: "#BDBDBD",
                            "&:hover": {
                              backgroundColor: "#BDBDBD",
                            },
                          }}
                          className={classes.buttonn}
                        >
                          Back
                        </Button>
                        <Box />

                        <Button
                          variant="contained"
                          className={classes.button}
                          onClick={addNewLearner}
                          disabled={!agree && activeStep === 1}
                          sx={{
                            backgroundColor: "#5335ca",
                            "&:hover": {
                              backgroundColor: "#4b27dd",
                            },
                          }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
                <Box>
                  {activeStep === 2 && (
                    <>
                      <Box>
                        <Alert className={classes.field} severity="info">
                          If you wish, you can edit your weekly training hours
                          as per your official apprenticeship training plan.
                          However, once submitted, this weekly training plan is
                          no longer editable.
                        </Alert>
                      </Box>
                      <Box
                        sx={{
                          border: "1px solid #aaaaaa",
                          borderRadius: "4px",
                          marginBottom: "5px",
                        }}
                      >
                        <form>
                          <TableContainer
                            sx={{
                              maxHeight: { xs: 270, sm: 259, md: 300 },
                            }}
                          >
                            <Table
                              // sx={{ minWidth: { sm: 250, md: 300 } }}
                              aria-label="simple table"
                            >
                              <TableHead
                                sx={{
                                  position: "sticky",
                                  top: 0,
                                  zIndex: 1,
                                  backgroundColor: "#E5E5E5",
                                }}
                              >
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      fontSize: {
                                        xs: "14px",
                                        sm: "16px",
                                        md: "18px",
                                      },
                                      fontWeight: "600",
                                    }}
                                  >
                                    Week
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      fontSize: {
                                        xs: "14px",
                                        sm: "16px",
                                        md: "18px",
                                      },
                                      fontWeight: "600",
                                    }}
                                    align="center"
                                  >
                                    Hours
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      fontSize: {
                                        xs: "14px",
                                        sm: "16px",
                                        md: "18px",
                                      },
                                      fontWeight: "600",
                                    }}
                                    align="center"
                                  >
                                    Minutes
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {newLearner &&
                                  newLearner.monthlyHourMinutesUpdated &&
                                  newLearner.monthlyHourMinutesUpdated.map(
                                    (duration, index) => (
                                      <TableRow
                                        key={duration?.weekName}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          sx={{
                                            fontSize: {
                                              xs: "11px",
                                              sm: "14px",
                                              md: "14px",
                                            },
                                            width: "13rem",
                                          }}
                                        >
                                          {duration?.weekName} - (
                                          {duration.startDate} -{" "}
                                          {duration.endDate})
                                        </TableCell>
                                        <TableCell
                                          sx={{ width: "6rem" }}
                                          align="center"
                                        >
                                          {
                                            // <input
                                            //   type="number"
                                            //   className="editHoursModalSignUp__table--input"
                                            //   name="hours"
                                            //   min="0"
                                            //   value={duration?.targetHours}
                                            //   onChange={(e) =>
                                            //     handleDurationChange(e, index)
                                            //   }
                                            // />
                                            // <input
                                            //   type="number"
                                            //   className="editHoursModalSignUp__table--input"
                                            //   name="hours"
                                            //   pattern="\d*"
                                            //   value={duration?.targetHours}
                                            //   onChange={(e) =>
                                            //     handleDurationChangeHours(
                                            //       e,
                                            //       index
                                            //     )
                                            //   }
                                            // />
                                            <input
                                              type="string"
                                              className="editHoursModalSignUp__table--input"
                                              name="hours"
                                              value={duration?.targetHours}
                                              onChange={(e) =>
                                                handleDurationChangeMinutes(
                                                  e,
                                                  index
                                                )
                                              }
                                            />
                                          }
                                        </TableCell>
                                        <TableCell
                                          sx={{ width: "6rem" }}
                                          align="center"
                                        >
                                          {
                                            <input
                                              type="string"
                                              className="editHoursModalSignUp__table--input"
                                              name="minutes"
                                              min="0"
                                              max="59"
                                              value={duration?.targetMinutes}
                                              onChange={(e) =>
                                                handleDurationChangeMinutes(
                                                  e,
                                                  index
                                                )
                                              }
                                            />
                                          }
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </form>

                        <Stack
                          direction="column"
                          alignItems="center"
                          color="rgba(0, 0, 0, 0.8)"
                          sx={{
                            padding: 0.5,
                            marginBottom: { xs: 1, sm: 1, md: 3 },
                          }}
                        >
                          <Stack direction="row" spacing={1}>
                            <span>
                              <p className="fontFixe">
                                Total Time:{" "}
                                {formatMinutesInOOOO(newLearner?.totalMinutes)}
                              </p>
                            </span>

                            <span>
                              <p className="fontFixe">
                                Updated Time:{" "}
                                {formatSecondsInOOOO(changedSeconds)}
                              </p>
                            </span>

                            <span>
                              <p className="fontFixe">
                                Time Difference: {getTimeDifference()}
                              </p>
                            </span>
                            <IconButton
                              onClick={calculateLearnerHoursHandler}
                              color="secondary"
                              aria-label="reset"
                            >
                              <RotateLeftIcon />
                            </IconButton>
                          </Stack>
                        </Stack>
                      </Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={agree}
                            onChange={handleAgreeChange}
                            inputProps={{
                              "aria-label":
                                "I agree to the Terms of Use and Privacy Policy",
                            }}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                color: "#5335CA", // Change the color here
                              },
                            }}
                          />
                        }
                        label={
                          <span>
                            I agree with the{" "}
                            <Link
                              href="https://7startraining.co.uk/termsofuse.html"
                              target="_blank"
                              rel="noopener"
                            >
                              Terms of Use
                            </Link>{" "}
                            and{" "}
                            <Link
                              href="https://7startraining.co.uk/privacypolicy.html"
                              target="_blank"
                              rel="noopener"
                            >
                              Privacy Policy
                            </Link>{" "}
                            upon signup.
                          </span>
                        }
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: {
                            xs: "center",
                            sm: "center",
                            md: "space-between",
                          },
                          mt: 1,
                          pb: 3,
                        }}
                      >
                        <Button
                          disableElevation
                          variant="contained"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{
                            mr: 1,
                            color: "white",
                            backgroundColor: "#BDBDBD",
                            "&:hover": {
                              backgroundColor: "#BDBDBD",
                            },
                          }}
                          className={classes.buttonn}
                        >
                          Back
                        </Button>
                        <Box />

                        <Button
                          variant="contained"
                          className={classes.button}
                          onClick={
                            activeStep === 2 ? addNewLearner : handleNext
                          }
                          disabled={!agree && activeStep === 2}
                          sx={{
                            backgroundColor: "#5335ca",
                            "&:hover": {
                              backgroundColor: "#4b27dd",
                            },
                          }}
                        >
                          {activeStep === steps.length - 1 ? "Submit" : "Next"}
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </React.Fragment>
            }
          </Box>
        </FormGroup>
      </Box>

      {/* </Modal> */}

      {/* Reset New Learner Durations Modal */}

      {/* Subscription Redirect Modal */}
      <ConfirmationModal
        open={redirectSubscriptionModal}
        close={() => setRedirectSubscriptionModal(!redirectSubscriptionModal)}
        title="Upgrade your plan"
        text="Please upgrade your subscription plan to add more learners."
        ctaTitleText="Upgrade"
        cta={redirectToStripe}
      />
    </Fragment>
  );
};

export default AddLearnerPage;
