import { Button, Popover, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import jwtDecode from "jwt-decode";
import { useState } from "react";
import { useContext } from "react";
import { Fragment } from "react";
import axios from "axios";
import { AppContext } from "../../context/appContext";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import "./RestrictFeature.css";
import UpgradePlanModal from "../UpgradePlanModal/UpgradePlanModal";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem 1.5rem",
  },
}));

export const RestrictFeature = (props) => {
  const classes = useStyles();
  const { data, handleToggle, updateMessage } = useContext(AppContext);
  const [redirectSubscriptionModal, setRedirectSubscriptionModal] =
    useState(false);

  // To Redirect to Stripe For payment
  const redirectToStripe = async () => {
    let token = localStorage.getItem("7-star-training");
    let decodedToken = jwtDecode(token.replace("Bearer ", "")); // decode your token here
    if (data.subscription.plan == "custom") {
      try {
        handleToggle(true);
        await axios
          .get("saas/pricing", {
            config: { handlerEnabled: true },
          })
          .then((response) => {
            let test ={...response}
            // console.log("response", test)
            handleToggle(false);
            window.location.href = response.data.data.url;
            // window.location.replace(response.data.data.url);
            // window.open(response.data.data.url, "_blank");
            setRedirectSubscriptionModal(!redirectSubscriptionModal);
          });
      } catch (e) {
        handleToggle(false);
        if (e.response && e.response.data) {
          updateMessage(e.response.data.message);
        }
      }
    } else {
      try {
        handleToggle(true);
        await axios
          .post(
            "saas/billing",
            { id: decodedToken.id },
            {
              config: { handlerEnabled: true },
            }
          )
          .then((response) => {
            handleToggle(false);
            window.location.href = response.data.data.url;
            // window.open(response.data.data.url, "_blank");
            setRedirectSubscriptionModal(!redirectSubscriptionModal);
            // setSubscriptionData(response.data.data);
            // setShowSubscription(!showSubscription);
            // handleToggle(false);
          });
      } catch (e) {
        handleToggle(false);
        if (e.response && e.response.data) {
          updateMessage(e.response.data.message);
        }
      }
    }
  };

  return (
    <Fragment>
      {props.available.includes(data.subscription.plan) ? (
        <Fragment>{props.children}</Fragment>
      ) : (
        <span
          onClick={(e) => {
            setRedirectSubscriptionModal(true);
          }}
          style={{ cursor: "pointer" }}
        >
          <span className="pointer-events-none">{props.children}</span>
        </span>
      )}
      <UpgradePlanModal
        open={redirectSubscriptionModal}
        close={() => setRedirectSubscriptionModal(!redirectSubscriptionModal)}
        // title="Upgrade your plan"
        // text="Please upgrade your subscription plan to use this feature."
        // ctaTitleText="Upgrade"
        cta={redirectToStripe}
      />

    </Fragment>
  );
};
