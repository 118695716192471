import React, {
  useEffect,
  useState,
  createContext,
  useLayoutEffect,
} from "react";
import axios from "axios";
// import jwt from "jwt-decode";
import { isEqual } from "lodash";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AppSnackbar from "../components/Snackbar/Snackbar";

export const AppContext = createContext([]);

export const AppProvider = (props) => {
  const [data, setData] = useState(null);
  const [loaderState, setLoaderState] = useState(false);
  const [message, setMessage] = useState(null);

  const getUserData = () => {
    let token = localStorage.getItem("7-star-training");
    if (token) {
      // setLoaderState(true);
      axios
        .get("/learner/profile", { config: { handlerEnabled: true } })
        .then((response) => {
          if (!isEqual(data, response.data.data)) {
            localStorage.setItem("7-star-training", response.data.token);
            setData(response.data.data);
          }
        })
        .catch((err) => {
          // localStorage.clear();
          // window.location.reload(false);
        });
    }
  };

  useEffect(() => {
    if (navigator.onLine) getUserData();
    // Define the function to call when visibility changes
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // console.log("User has returned to the tab");
        // Call any function you like here
        getUserData();
      }
    };

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To Fetch All Data When User Back TYo Page After Visiting Another Tab
  // const onVisibilityChange = async () => {
  //   let hidden;
  //   // let visibilityChange;

  //   if (typeof document.hidden !== "undefined") {
  //     // Opera 12.10 and Firefox 18 and later support
  //     hidden = "hidden";
  //     // visibilityChange = "visibilitychange";
  //   } else if (typeof document.msHidden !== "undefined") {
  //     hidden = "msHidden";
  //     // visibilityChange = "msvisibilitychange";
  //   } else if (typeof document.webkitHidden !== "undefined") {
  //     hidden = "webkitHidden";
  //     // visibilityChange = "webkitvisibilitychange";
  //   }

  //   if (!document[hidden]) {
  //     if (navigator.onLine) getUserData();
  //   }
  // };

  // useLayoutEffect(() => {
  //   document.addEventListener("visibilitychange", onVisibilityChange);

  //   return () =>
  //     document.removeEventListener("visibilitychange", onVisibilityChange);
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const handleClose = () => {
  //   setLoaderState(false);
  // };

  const handleToggle = (value) => {
    setLoaderState(value);
  };

  const updateMessage = (message) => {
    setMessage(message);
    setTimeout(() => {
      setMessage(null);
    });
  };

  return (
    <AppContext.Provider
      value={{ data, setData, handleToggle, updateMessage, getUserData }}
    >
      {props.children}
      <AppSnackbar message={message}></AppSnackbar>
      {/* <Backdrop style={{ zIndex: 10000 }} open={loaderState} onClick={handleClose}> */}
      <Backdrop style={{ zIndex: 10000 }} open={loaderState}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </AppContext.Provider>
  );
};
