import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../context/appContext";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Grid,
  Box,
  FormGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Autocomplete,
  Typography,
  Checkbox,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Drawer,
  useTheme,
  useMediaQuery,
  IconButton,
  Avatar,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import learner, {
  getLearners,
  setAllLearners,
} from "../../redux/slices/learner";
import { useDispatch, useSelector } from "../../redux/store";
import { useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stack } from "@mui/system";
import { Profiler } from "react";
import Alert from "@mui/material/Alert";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import enLocale from "date-fns/locale/en-GB";
import dayjs from "dayjs";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 550,
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: 2,
  p: 4,
};

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 20,
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
    cursor: "pointer",
    zIndex: 10,
  },
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
  buttonn: {
    background: "#939393",
    "&:hover": {
      background: "#888888",
    },
    marginRight: 10,
  },
}));

const UpdateLearner = ({
  open,
  close,
  tempLearner,
  setTempLearner,
  newLearnerValidations,
}) => {
  const dispatch = useDispatch();

  const updateNewLearnerChangeHandler = (date, field) => {
    if (date) {
      const formattedDate = dayjs(date).format("DD-MM-YYYY");
      setTempLearner((prevLearner) => ({
        ...prevLearner,
        [field]: formattedDate,
      }));
    }
  };
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  let { id } = useParams();
  const [profile, setProfile] = useState({
    name: "",
    imageUrl: "",
    newImage: null,
  });

  const { handleToggle, updateMessage, data, getUserData } =
    useContext(AppContext);

  // console.log("data", data);
  const { allFaculties } = useSelector((state) => state.faculty);
  const [filteredFaculties, setFilteredFaculties] = useState({
    coach: [],
    manager: [],
    mentor: [],
    visitor: [],
    trainer: [],
  });
  const [learnerValidations, setLearnerValidations] = useState(
    newLearnerValidations
  );

  // FormHandler For Update Learner Modal
  // const updateLearnerChangeHandler = (e, selectInput) => {
  //   if (e && e.target && e.target.name) {
  //     let { name, value, checked } = e.target;

  //     if (name && name === "isLearnerActive") {
  //       setTempLearner({
  //         ...tempLearner,
  //         active: checked,
  //       });
  //     } else if (name) {
  //       setTempLearner({
  //         ...data,
  //         [name]: value,
  //       });

  //       if (value && learnerValidations[name]) {
  //         setLearnerValidations({
  //           ...learnerValidations,
  //           [name]: false,
  //         });
  //       }
  //     }
  //   } else if (
  //     e &&
  //     (selectInput === "coach" ||
  //       selectInput === "manager" ||
  //       selectInput === "mentor" ||
  //       selectInput === "visitor" ||
  //       selectInput === "trainer")
  //   ) {
  //     setTempLearner({
  //       ...tempLearner,
  //       [selectInput]: e,
  //     });
  //   }
  // };

  const updateLearnerChangeHandler = (e) => {
    if (e && e.target && e.target.name) {
      let { name, value, checked } = e.target;

      if (
        name === "trainingProviderName" ||
        name === "employerName" ||
        name === "apprenticeshipStandard"
      ) {
        if (value.trim() === "") {
          setTempLearner({
            ...tempLearner,
            [name]: value,
          });
          if (learnerValidations[name]) {
            setLearnerValidations({
              ...learnerValidations,
              [name]: true, // Set validation to true if the field is empty
            });
          }
        } else {
          setTempLearner({
            ...tempLearner,
            [name]: value,
          });
          if (learnerValidations[name]) {
            setLearnerValidations({
              ...learnerValidations,
              [name]: false, // Set validation to false if the field is not empty
            });
          }
        }
      } else if (name === "isLearnerActive") {
        setTempLearner({
          ...tempLearner,
          active: checked,
        });
      } else {
        setTempLearner({
          ...tempLearner,
          [name]: value,
        });

        if (value && learnerValidations[name]) {
          setLearnerValidations({
            ...learnerValidations,
            [name]: false,
          });
        }
      }
    }
  };

  useEffect(() => {
    // Replace this with your actual data fetching logic
    const fetchData = async () => {
      try {
        // Assume you have an API endpoint to get user data

        populateForm(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [open]);

  const populateForm = (responseData) => {
    setTempLearner(responseData);
  };
  // FormHandler For Update Learner Modal CheckBoxes
  const updateLearnerCheckboxHandler = (e) => {
    let { name } = e.target;

    setTempLearner({
      ...tempLearner,
      [name]: !tempLearner[name],
    });
  };

  // To Update Learner
  // const updateLearner = async (data) => {
  //   // 1- Validations
  //   // if (tempLearner.uln === "") {
  //   //   setLearnerValidations({
  //   //     ...learnerValidations,
  //   //     uln: true,
  //   //   });
  //   //   return;
  //   // }

  //   if (tempLearner.name === "") {
  //     setLearnerValidations({
  //       ...learnerValidations,
  //       name: true,
  //     });
  //     return;
  //   }
  //   if (tempLearner.email === "") {
  //     setLearnerValidations({
  //       ...learnerValidations,
  //       name: true,
  //     });
  //     return;
  //   }
  //   // if (tempLearner.trainingProviderName === "") {
  //   //   setLearnerValidations({
  //   //     ...learnerValidations,
  //   //     trainingProviderName: true,
  //   //   });
  //   //   return;
  //   // }
  //   // if (tempLearner.employerName === "") {
  //   //   setLearnerValidations({
  //   //     ...learnerValidations,
  //   //     employerName: true,
  //   //   });
  //   //   return;
  //   // }
  //   // if (tempLearner.apprenticeshipStandard === "") {
  //   //   setLearnerValidations({
  //   //     ...learnerValidations,
  //   //     apprenticeshipStandard: true,
  //   //   });
  //   //   return;
  //   // }

  //   // 2- Assignments
  //   // let coachMentor = allFaculties.filter(
  //   //   (faculty) =>
  //   //     faculty.email === tempLearner.coach ||
  //   //     faculty.email === tempLearner.manager ||
  //   //     faculty.email === tempLearner.mentor ||
  //   //     faculty.email === tempLearner.visitor ||
  //   //     faculty.email === tempLearner.trainer
  //   // );

  //   let learner = {
  //     // ...tempLearner,
  //     email: tempLearner.email,
  //     uln: tempLearner.uln,
  //     name: tempLearner.name,
  //     trainingProviderName: tempLearner.trainingProviderName,
  //     employerName: tempLearner.employerName,
  //     apprenticeshipStandard: tempLearner.apprenticeshipStandard,
  //     // coachMentor: coachMentor,
  //     active: tempLearner.active,
  //     imageUrl: tempLearner.imageUrl,
  //     startDate: tempLearner.startDate,
  //     endDate: tempLearner.endDate,
  //   };

  //   // 3- Api
  //   try {
  //     handleToggle(true);
  //     let obj = {
  //       ...data,
  //       ...profile,
  //       notifyUser: false,
  //     };
  //     // console.log("obj",obj)
  //     let formData = new FormData();
  //     formData.append("file", profile.newImage);
  //     formData.append("data", JSON.stringify(learner));
  //     // console.log("first");

  //     await axios
  //       .put("learner", formData, {
  //         config: { handlerEnabled: true },
  //       })
  //       .then((response) => {
  //         // console.log("response", response);

  //         obj.imageUrl = response.data.data.imageUrl;
  //         close();
  //         handleToggle(false);
  //         updateMessage(response.data.message);
  //         // window.location.reload(false);
  //         getUserData();
  //       });
  //   } catch (e) {
  //     handleToggle(false);
  //     if (e.response && e.response.data) {
  //       updateMessage(e.response.data.message);
  //     }
  //   }
  // };

  const updateLearner = async (data) => {
    // 1- Validations
    if (tempLearner.name === "") {
      setLearnerValidations({
        ...learnerValidations,
        name: true,
      });
      return;
    }
    if (tempLearner.email === "") {
      setLearnerValidations({
        ...learnerValidations,
        email: true,
      });
      return;
    }

    // 2- Calculate Changed Durations
    let changedSeconds = 0;
    if (
      tempLearner &&
      tempLearner.monthlyHourMinutesUpdated &&
      tempLearner.monthlyHourMinutesUpdated.length > 0
    ) {
      let changedHours = tempLearner.monthlyHourMinutesUpdated.map(
        (x) => x.targetHours
      );
      let changedMinutes = tempLearner.monthlyHourMinutesUpdated.map(
        (x) => x.targetMinutes
      );

      changedHours = changedHours.reduce((a, b) => a + b, 0);
      changedMinutes = changedMinutes.reduce((a, b) => a + b, 0);

      changedSeconds = Math.floor(changedHours * 3600 + changedMinutes * 60);
    }

    // 3- Recalculate or Modify monthlyHourMinutes
    let updatedMonthlyHourMinutes = tempLearner.monthlyHourMinutes.map(
      (entry) => {
        // Find the corresponding update for the current month
        let update = tempLearner.monthlyHourMinutesUpdated?.find(
          (updatedEntry) => updatedEntry.month === entry.month
        );

        if (update) {
          // Modify the entry with the updated target hours and minutes
          return {
            ...entry,
            targetHours: update.targetHours,
            targetMinutes: update.targetMinutes,
          };
        }

        return entry; // If no update, return the original entry
      }
    );

    // 4- Prepare Learner Object
    let learner = {
      email: tempLearner.email,
      uln: tempLearner.uln,
      name: tempLearner.name,
      trainingProviderName: tempLearner.trainingProviderName,
      employerName: tempLearner.employerName,
      apprenticeshipStandard: tempLearner.apprenticeshipStandard,
      active: tempLearner.active,
      imageUrl: tempLearner.imageUrl,
      startDate: tempLearner.startDate,
      endDate: tempLearner.endDate,
      monthlyHourMinutes: updatedMonthlyHourMinutes, // Use updated array
      changedDuration: changedSeconds, // Optional: Use changed duration if needed
    };

    // 5- API Call
    try {
      handleToggle(true);
      let obj = {
        ...data,
        ...profile,
        notifyUser: false,
      };

      let formData = new FormData();
      formData.append("file", profile.newImage);
      formData.append("data", JSON.stringify(learner));

      await axios
        .put("learner", formData, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          obj.imageUrl = response.data.data.imageUrl;
          close();
          handleToggle(false);
          updateMessage(response.data.message);
          getUserData();
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  // To Set/ Update The Filtered Faculties When 'faculties' State Changes
  useEffect(() => {
    if (allFaculties && allFaculties.length > 0) {
      let coach = [],
        manager = [],
        mentor = [],
        visitor = [],
        trainer = [];

      for (let faculty of allFaculties) {
        if (faculty?.role === "coach") {
          coach.push(faculty);
        } else if (faculty?.role === "manager") {
          manager.push(faculty);
        } else if (faculty?.role === "mentor") {
          mentor.push(faculty);
        } else if (faculty?.role === "visitor") {
          visitor.push(faculty);
        } else if (faculty?.role === "trainer") {
          trainer.push(faculty);
        }
      }
      setFilteredFaculties({
        coach,
        manager,
        mentor,
        visitor,
        trainer,
      });
    }
  }, [allFaculties]);

  const newPictureHandler = async (e) => {
    e.preventDefault();
    let image = e.target.files[0];
    // console.log("image", image);
    setProfile({
      ...profile,
      newImage: image,
      imageUrl: URL.createObjectURL(image),
    });
  };

  const deleteProfilePicture = async () => {
    setProfile({
      ...profile,
      imageUrl: null,
      newImage: null,
    });
    setTempLearner({
      ...tempLearner,
      imageUrl: null,
    });
  };

  useEffect(() => {
    setProfile({
      ...profile,
      imageUrl: data?.imageUrl,
    });
  }, [open]);

  return (
    // <Modal
    //   open={open}
    //   onClose={close}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    // >
    <Drawer
      anchor="right"
      open={open}
      onClose={close}
      PaperProps={{
        sx: { width: matches ? "45%" : "100%" },
      }}
    >
      <Box sx={{ m: 2 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="drawerHeader"
        >
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h3"
            component="h2"
          >
            Update Profiles
          </Typography>
          <CloseIcon className={classes.icons} title="Close" onClick={close} />
        </Grid>
        <FormGroup sx={{ paddingTop: 3 }}>
          <div className="profileImage">
            <div className="profileImageWrapper">
              <span>
                <Avatar
                  alt={tempLearner && tempLearner.name}
                  src={profile.imageUrl}
                  sx={{ width: 100, height: 100 }}
                />
              </span>
              <input
                id="selectImage"
                name="imageUrl"
                accept="image/*"
                type="file"
                hidden
                onChange={(e) => newPictureHandler(e)}
              />
              <EditIcon
                className="editIcon"
                title="Edit"
                onClick={() => {
                  document.getElementById("selectImage").click();
                }}
              />

              {tempLearner?.imageUrl && (
                <DeleteIcon
                  className="deleteIcon"
                  title="Delete"
                  onClick={() => deleteProfilePicture()}
                />
              )}
            </div>
          </div>
          <TextField
            label="ULN (Unique Learner Number)"
            variant="outlined"
            name="uln"
            className={classes.field}
            value={tempLearner?.uln}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.uln}
            helperText={learnerValidations.uln && "This can't be empty."}
          />
          <Grid container spacing={3} className={classes.field}>
            <Grid item sm={12} md={12} className="fullWidthInput">
              <TextField
                label="Name"
                variant="outlined"
                name="name"
                value={tempLearner?.name}
                onChange={(e) => updateLearnerChangeHandler(e)}
                error={learnerValidations.name}
                helperText={learnerValidations.name && "This can't be empty."}
              />
            </Grid>
          </Grid>

          <TextField
            label="Work Email"
            variant="outlined"
            className={classes.field}
            type="email"
            name="email"
            value={tempLearner?.email}
            // disabled
            // value={tempLearner?.name}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.email}
            helperText={learnerValidations.email && "This can't be empty."}
          />

          <TextField
            label="Training Provider Name"
            variant="outlined"
            className={classes.field}
            type="email"
            name="trainingProviderName"
            value={tempLearner?.trainingProviderName}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.trainingProviderName}
            helperText={
              learnerValidations.trainingProviderName && "This can't be empty."
            }
          />

          <TextField
            label="Employer Name"
            variant="outlined"
            className={classes.field}
            name="employerName"
            value={tempLearner?.employerName}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.employerName}
            helperText={
              learnerValidations.employerName && "This can't be empty."
            }
          />

          <TextField
            label="Apprenticeship Standard"
            variant="outlined"
            className={classes.field}
            name="apprenticeshipStandard"
            value={tempLearner?.apprenticeshipStandard}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.apprenticeshipStandard}
            helperText={
              learnerValidations.apprenticeshipStandard &&
              "This can't be empty."
            }
          />
          {/* dates  */}
          <TextField
            label="Apprenticeship Standard"
            variant="outlined"
            className={classes.field}
            name="apprenticeshipStandard"
            value={tempLearner?.apprenticeshipStandard}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.apprenticeshipStandard}
            helperText={
              learnerValidations.apprenticeshipStandard &&
              "This can't be empty."
            }
          />
          <TextField
            label="Apprenticeship Standard"
            variant="outlined"
            className={classes.field}
            name="apprenticeshipStandard"
            value={tempLearner?.apprenticeshipStandard}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.apprenticeshipStandard}
            helperText={
              learnerValidations.apprenticeshipStandard &&
              "This can't be empty."
            }
          />
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h3"
            component="h2"
            sx={{ pb: 2 }}
          >
            Update Practical Period
          </Typography>
          <Box sx={{ mb: "10px" }}>
            <Alert className={classes.field} severity="info">
              Please remeber if you edit the Start and End dates of the Planned
              Off-The-Job-Hours,the application will recalculate the weekly
              Off-The-Job-Hours
            </Alert>
          </Box>
          <Grid container spacing={3} direction="column">
            <Grid item sm={12} md={6} className="fullWidthInput">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                locale={enLocale}
              >
                <DatePicker
                  label="Start Date"
                  name="startDate"
                  value={dayjs(tempLearner?.startDate, "DD-MM-YYYY")} // Ensure the value is in a valid Day.js format
                  showToolbar={false}
                  onChange={(date) =>
                    updateNewLearnerChangeHandler(date, "startDate")
                  }
                  error={learnerValidations?.startDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  )}
                  format="DD-MM-YYYY"
                  inputFormat="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={12} md={6} className="fullWidthInput">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                locale={enLocale}
              >
                <DatePicker
                  label="End Date"
                  name="endDate"
                  value={dayjs(tempLearner?.endDate, "DD-MM-YYYY")} // Ensure the value is in a valid Day.js format
                  showToolbar={false}
                  onChange={(date) =>
                    updateNewLearnerChangeHandler(date, "endDate")
                  }
                  error={learnerValidations?.endDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  )}
                  format="DD-MM-YYYY"
                  inputFormat="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </FormGroup>
        <div className="drawerFooter editLearnerModal__footer">
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Button
              variant="contained"
              className={classes.button}
              onClick={updateLearner}
            >
              Save
            </Button>

            <Button
              variant="contained"
              className={classes.buttonn}
              onClick={close}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </Drawer>
  );
};

export default UpdateLearner;
