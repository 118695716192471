import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import GetAppIcon from "@mui/icons-material/GetApp";
import { RestrictFeature } from "../../components/RestrictFeature/RestrictFeature";
export default function PositionedMenu({
  downloadHandler,
  downloadEvidenceReport,
  downloadSingleDiaryHandler,
  handleClose,
  handleDiariesDownload,
  data,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosee = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip arrow title="Download" placement="right">
        <IconButton onClick={handleClick}>
          <DownloadIcon sx={{ width: "1rem" }} />
        </IconButton>
      </Tooltip>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClosee}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClosee}>
          <Button
            sx={{
              backgroundColor: "#5335CA",
              color: "white",
              fontSize: "0.75rem",
              fontWeight: 500,
              justifyContent: "left",
              "&:hover": {
                backgroundColor: "#5335CA",
                color: "white",
              },
            }}
            fullWidth
            onClick={downloadHandler}
            startIcon={<GetAppIcon size="small" />}
          >
            LEARNER REPORT
          </Button>
        </MenuItem>
        <MenuItem>
          <RestrictFeature available={["standard"]}>
            <Button
              sx={{
                backgroundColor: "#5335CA",
                color: "white",
                pr: 4,
                fontSize: "0.75rem",
                fontWeight: 500,
                justifyContent: "left",
                "&:hover": {
                  backgroundColor: "#5335CA",
                  color: "white",
                },
              }}
              fullWidth
              onClick={() => downloadEvidenceReport(data._id)}
              startIcon={<GetAppIcon size="small" />}
            >
              OTJ EVIDENCE
            </Button>
          </RestrictFeature>
        </MenuItem>
        <MenuItem onClick={handleClosee}>
          <Button
            sx={{
              backgroundColor: "#5335CA",
              color: "white",
              fontSize: "0.75rem",
              fontWeight: 500,
              justifyContent: "left",
              "&:hover": {
                backgroundColor: "#5335CA",
                color: "white",
              },
            }}
            fullWidth
            onClick={() => handleDiariesDownload(data._id, data?.name)}
            startIcon={<GetAppIcon size="small" />}
          >
            LEARNER DIARIES
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
}
