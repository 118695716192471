import React, { useEffect, useState, Fragment, useContext } from "react";
import "./LearnerGraphDrawer.css";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Modal,
  Button,
  Box,
  Typography,
  Drawer,
  useTheme,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  formatMinutesInOOOO,
  formatSecondsInOOOO,
} from "../../utils/functions";
import { cloneDeep } from "lodash";
import { AppContext } from "../../context/appContext";
import axios from "axios";
import jwtDecode from "jwt-decode";
import WeeklyOffTheJobHoursGraph from "./graphs/WeeklyOffTheJobHoursGraph";
import MonthlyOffTheJobHoursGraph from "./graphs/MonthlyOffTheJobHoursGraph";
import LearningActivitiesHoursCompletedTable from "./table/LearningActivitiesHoursCompletedTable";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "lightgray",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 4,
  },
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
  icons: {
    cursor: "pointer",
  },
  indicator: {
    backgroundColor: "#5335ca",
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 550,
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: 2,
  p: 4,
};

export default function LearnerGraphDrawer(props) {
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [chartData, setChartData] = useState([]);
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [learnerId, setLearnerId] = useState(null); // State to store learner ID

  // Fetch learner profile to get learner ID
  useEffect(() => {
    const fetchLearnerProfile = async () => {
      try {
        const response = await axios.get(`/learner/profile`, {
          config: { handlerEnabled: true },
        });
        // console.log("response", response);

        const learnerId = response.data.data._id; // Adjust based on your API response
        // console.log("setLearnerId", learnerId);
        setLearnerId(learnerId);
      } catch (e) {
        if (e.response && e.response.data) {
          setError(e.response.data.message);
        } else {
          setError(e.message);
        }
        setLoading(false);
      }
    };

    fetchLearnerProfile();
  }, []); // Run only once when the component mounts

  // Fetch chart data when learner ID is available
  useEffect(() => {
    if (learnerId) {
      const fetchChartData = async () => {
        try {
          setLoading(true);
          const response = await axios.get(`/learner/chart-data/${learnerId}`, {
            config: { handlerEnabled: true },
          });
          const response1 = await axios.get(`/learner/profile`, {
            config: { handlerEnabled: true },
          });
          setResponse(response);
          // console.log("response", response.data.weeklyData);

          const { data } = response.data;
          const weekly = data?.weeklyData;

          setChartData(weekly);
          setLoading(false);
        } catch (e) {
          setError(e.message);
          setLoading(false);
        }
      };

      fetchChartData();
    }
  }, [learnerId, props.open]); // Fetch chart data whenever learnerId changes

  if (loading && !learnerId) return <p>Loading learner profile...</p>;
  if (loading) return <p>Loading chart data...</p>;
  if (error) return <p>Error: {error}</p>;
  return (
    <Drawer
      anchor="right"
      open={props.open}
      onClose={props.close}
      PaperProps={{
        sx: { width: matches ? "60%" : "100%" },
      }}
    >
      <Box sx={{ m: "16px" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="drawerHeader"
        >
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h3"
            // sx={{ pl: 3 }}
          >
            Weekly Off-The-Job Hours
          </Typography>
          <CloseIcon
            className={classes.icons}
            title="Close"
            onClick={props.close}
          />
        </Grid>
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h3"
          sx={{ pt: 2 }}
        >
          Weekly Off-The-Job Hours
        </Typography>
        <Divider sx={{ pt: 2 }} />

        <Box sx={{ p: 0 }}>
          <WeeklyOffTheJobHoursGraph chartData={response} />
        </Box>
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h3"
          sx={{ pt: 6 }}
        >
          Monthly Off-The-Job Hours Completed
        </Typography>
        <Divider sx={{ pt: 2 }} />
        <Box>
          <MonthlyOffTheJobHoursGraph chartData={response} />
        </Box>

        <Box sx={{ pt: 6 }}>
          <LearningActivitiesHoursCompletedTable chartData={response} />
        </Box>
      </Box>
    </Drawer>
  );
}
