import React, { useContext } from "react";
import "./CompanyListModal.css";
import axios from "axios";
import { Album as AlbumIcon, Close as CloseIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Modal, Box, Grid, Typography, Button } from "@mui/material";
// import { useHistory, Link as RouterLink } from "react-router-dom";
import { AppContext } from "../../context/appContext";
// import { matches } from "lodash";

const CompanyListModal = ({ open, close, companies }) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100vw",
    maxHeight: 550,
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    overflow: "auto",
    borderRadius: 2,
    p: 4,
  };

  const useStyles = makeStyles(() => ({
    paper: {
      backgroundColor: "lightgray",
      border: "2px solid #000",
      boxShadow: 24,
      padding: 4,
    },
    button: {
      background: "#5335CA",
      color: "white",
      "&:hover": {
        background: "#5335CA",
      },
    },
    icons: {
      cursor: "pointer",
    },
    selectedRow: {
      // backgroundColor: "grey"

    },
    isSelected: {
      marginLeft: "8px",
    },
  }));

  // data, setData, handleToggle, updateMessage, getUserData
  const { data, setData, handleToggle, updateMessage } = useContext(AppContext);

  // console.log(data);

  const classes = useStyles();
  // const history = useHistory();

  const loginWithCompany = async (company) => {
    try {
      handleToggle(true);
      await axios
        .get(`general/login/${company}`, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          //   close();
          localStorage.setItem("7-star-training", response.data.token);
          setData(response.data.data);
          //   history.replace(`/${response.data.data.role}/${response.data.data._id}`);
          window.history.replaceState(
            null,
            `/${response.data.data.role} | ${response.data.data.company}`,
            `/${response.data.data.role}/${response.data.data._id}`
          );
          handleToggle(false);
          window.location.reload(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);
        updateMessage(e.response.data.message);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle} className="modalContainer">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="modalHeader"
        >
          <Typography id="modal-modal-title" variant="h5" component="h3" component="h2">
            Select Organisation
          </Typography>
          <CloseIcon className={classes.icons} title="Close" onClick={close} />
        </Grid>

        <div className="modal__body">
          <div className="learnerProfileInfo__group">
            <Typography component="h3" className="learnerProfileInfo__text">
              Select an organisation to proceed.
            </Typography>
          </div>

          <ul>
            {companies &&
              companies.map((company) => (
                <li
                  className={classes.selectedRow}
                  key={company.id * Math.random()}
                  onClick={() => {
                    loginWithCompany(company.sanitizeCompanyName);
                  }}
                >
                  <AlbumIcon className={classes.icons} />
                  <span>{company.rawCompanyName}</span>
                  {data &&
                    data.sanitizeCompanyName ===
                    company.sanitizeCompanyName && (
                      <span className={classes.isSelected}>(Current)</span>
                    )}
                </li>
              ))}
          </ul>
        </div>

        <div className="modalFooter">
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Button onClick={close} className={classes.button}>
              Close
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default CompanyListModal;
