import React from "react";
import {
  Box,
  Typography,
  Stack,
  Button,
  Divider,
  IconButton,
  Breadcrumbs,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Icon } from "@iconify/react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const CancelPlan = ({ plan, setPlan }) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ paddingY: "2rem" }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ marginBottom: "2rem" }}
        >
          <Button
            color="inherit"
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
            onClick={() => setPlan(null)}
          >
            Billings
          </Button>
          <Button
            color="inherit"
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
            disabled
          >
            {/* {plan === "Upgrade" ? "Upgrade" : "Cancel"} */}
            {plan}
          </Button>
        </Breadcrumbs>
        <Typography variant="h4" component="h4" marginBottom={"3rem"}>
          Cancel Your plan
        </Typography>
        <Typography
          variant="h5" component="h3"
          component="h6"
          fontSize="1rem"
          textAlign={"left"}
        >
          Current Plan
        </Typography>
        <Divider
          sx={{
            height: "1px",
            background: "lightgrey",
            marginBottom: "2rem",
            marginTop: ".5rem",
          }}
        />

        <Stack direction="row" gap="5rem">
          <Stack direction="column">
            <Typography variant="h4" component="h4" fontSize={"1.25rem"}>
              Basic
            </Typography>
            <Typography variant="body" component="p" fontSize={"1.5rem"}>
              £20.00 per year
            </Typography>
            <Typography variant="body" component="p">
              Your plan renews on November 8, 2023.
            </Typography>
          </Stack>

          <Stack>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={() => setPlan("Upgrade")}
            >
              Update Plan
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default CancelPlan;
