import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  // DialogTitle,
  // DialogContentText,
  // DialogContent,
  // DialogActions,
  // Dialog,
  TextField,
  FormControl,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  // Tooltip,
  Box,
  Grid,
  FormGroup,
  Drawer,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
// import DatePicker from "@mui/lab/DatePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import TimeInput from "../TimeInput/TimeInput";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UpdateImageDropzone from "../UpdateImageDropzone/UpdateImageDropzone";
import axios from "axios";
import { AppContext } from "../../context/appContext";
import enLocale from "date-fns/locale/en-GB";
import { getDateFromUkString } from "../../utils/functions";
import { Stack } from "@mui/system";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: 2,
  p: 4,
};
const useStyles = makeStyles((theme) => ({
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
  divStyle: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    gap: "2rem",
    alignItems: "center",
    marginTop: ".5rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "1rem",
      width: "100% !important",
    },
  },
  cardStyle: {
    marginTop: ".7rem",
  },
  accordianBox: {
    padding: "10px 0",
    borderRadius: 4,
    margin: ".7rem 0",
  },
}));

export default function UpdateDiaryModal(props) {
  // initial state variable
  let initialState = {
    diaryState: {
      title: "",
      description: "",
      requestAcknowledgement: true,
      attachments: [],
      files: [],
    },
    activityState: {
      type: "Not OTJ Learning Activity",
      startDate: new Date(),
      endDate: new Date(),
      hours: "",
      minutes: "",
    },
    standardsState: {
      duties: "",
      knowledge: "",
      skills: "",
      behaviour: "",
      others: "",
    },
    errorState: {
      titleError: "",
      descriptionError: "",
      hoursError: "",
      minutesError: "",
    },
  };
  //learning activity type menu items array
  const learningActivityFilter = [
    "Classroom Training",
    "Training at Work",
    "Learning Support",
    "Lectures",
    "Online Learning",
    "Shadowing",
    "Mentoring",
    "Writing Assignments",
    "Theory Learning",
    "Manufacturer Training",
    "Role Playing",
    "Simulation Exercises",
    "Industry Visits",
    "Participation in Competitions",
    "Revision",
    "Other OTJ Learning Activity",
    "Not OTJ Learning Activity",
  ];

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  // data, setData, handleToggle, updateMessage, getUserData
  const { data, handleToggle, updateMessage } = useContext(AppContext);
  // const [userData, setUserData] = useState();

  //local states
  //   const [data, setData] = useState();
  const [diary, setDiary] = useState(initialState.diaryState);
  const [learningActivity, setLearningActivity] = useState(
    initialState.activityState
  );
  const [occupationalStandards, setOccupationalStandards] = useState(
    initialState.standardsState
  );
  const [error, setError] = useState(initialState.errorState);

  // setting up the local states on dialog open
  useEffect(() => {
    if (props.data) {
      // console.log("Hello", typeof props.data.acknowledgement, props.data);
      setOccupationalStandards(props.data.standards);
      setLearningActivity(props.data.activity);
      setDiary({
        ...diary,
        attachments: props.data.attachments,
        title: props.data.title,
        description: props.data.description,
        requestAcknowledgement: props.data.acknowledgement,
      });
      //   setData(props.data);

      // console.log(props.data.standards);
      // console.log(props.data.activity);
      // console.log(props.data.title, props.data.description);
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  // diary change handler
  const diaryChangeHandler = (e) => {
    // console.log(e.target.name, e.target.value);
    setError({
      ...error,
      [e.target.name + "Error"]: e.target.value ? false : true,
    });
    if (e.target.name === "requestAcknowledgement") {
      setDiary({
        ...diary,
        requestAcknowledgement: !diary.requestAcknowledgement,
      });
    } else {
      setDiary({ ...diary, [e.target.name]: e.target.value });
    }
  };
  // learning activity type change handler
  const learningActivityChangeHandler = (e) => {
    setLearningActivity({
      ...learningActivity,
      type: e.target.value,
    });
  };
  // LA start date handler
  const handleStartDateChange = (date) => {
    setLearningActivity({ ...learningActivity, startDate: date });
  };
  //LA end date handler
  const handleEndDateChange = (date) => {
    setLearningActivity({ ...learningActivity, endDate: date });
  };

  //occupationa Standards Change Handler
  const occupationalStandardsChangeHandler = (e) => {
    setOccupationalStandards({
      ...occupationalStandards,
      [e.target.name]: e.target.value,
    });
  };

  // validate edit form
  const validateForm = () => {
    let titleError = "";
    let descriptionError = "";
    let hoursError = "";
    let minutesError = "";

    if (diary.title === "") {
      titleError = "Please enter the title";
    }
    if (diary.description === "") {
      descriptionError = "Please enter the description";
    }
    if (learningActivity.type !== "Not OTJ Learning Activity") {
      if (learningActivity.hours === "") {
        hoursError = "Please enter the hours";
      }
      if (learningActivity.minutes === "") {
        minutesError = "Please enter the minutes";
      }
    }
    if (titleError || descriptionError || hoursError || minutesError) {
      setError({
        ...error,
        titleError,
        descriptionError,
        hoursError,
        minutesError,
      });
      return false;
    }
    return true;
  };

  const submitHandler = async () => {
    let isValid = validateForm();
    if (isValid) {
      //   Submit data
      let { files } = diary;
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      let data = {
        title: diary.title,
        description: diary.description,
        acknowledgement: diary.requestAcknowledgement,
        standards: occupationalStandards,
        activity: learningActivity,
        attachments: diary.attachments,
      };
      formData.append("data", JSON.stringify(data));
      try {
        handleToggle(true);
        await axios
          .put(`/learner/entry/${props.data._id}`, formData, {
            config: { handlerEnabled: true },
          })
          .then((response) => {
            // console.log(response.data.data);
            props.refreshData();
            handleToggle(false);
            updateMessage(response.data.message);
            props.closeModal();
          });
      } catch (e) {
        handleToggle(false);
        props.closeModal();
        // console.log("E Response", e.response);
        if (e.response && e.response.data) {
          console.error(e);

          if (e?.response?.status === 400) {
            props.closeModal();
            props.setApprentiseshipStatusModal(true);
            props.setErrorMsg(e.response.data.message);
          }
        }
      }
    } else {
      props.closeModal();
      updateMessage("Please fill all required fields");
    }
  };

  return (
    <div>
      {props.data && (
        <Drawer
          anchor="right"
          open={props.data}
          onClose={props.closeModal}
          PaperProps={{
            sx: { width: matches ? "40%" : "100%" },
          }}
        >
          <Box sx={{ m: 2 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="drawerHeader"
            >
              <Typography id="modal-modal-title" variant="h5" component="h3">
                Update Diary Entry
              </Typography>
              <CloseIcon
                className={classes.icons}
                title="Close"
                onClick={props.closeModal}
              />
            </Grid>
            <FormGroup sx={{ paddingTop: 3 }}>
              <TextField
                id="title"
                margin="dense"
                label="Title"
                type="text"
                fullWidth
                name="title"
                onChange={diaryChangeHandler}
                error={error.titleError}
                value={diary.title}
              />
              <TextField
                id="description"
                margin="dense"
                label="Description"
                type="text"
                multiline
                fullWidth
                name="description"
                onChange={diaryChangeHandler}
                error={error.descriptionError}
                value={diary.description}
              />

              {/* File Upload Component */}
              {/* <UpdateImageDropzone
                edit="true"
                diary={diary}
                setDiary={setDiary}
              /> */}
              {data && data.role === "learner" && (
                <Fragment>
                  <Accordion className={classes.accordianBox}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Edit learning activity</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormControl
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        className={classes.form}
                      >
                        <FormControl
                          className={classes.field}
                          error={error.chooseActivityError}
                        >
                          <InputLabel id="demo-simple-select-error-label">
                            Learning Activity
                          </InputLabel>
                          <Select
                            disabled={
                              props.userData &&
                              (props.userData.progress.apprenticeshipMessage !==
                                "Practical Period In Progress" ||
                                props.userData.status !== "On")
                            }
                            labelId="demo-simple-select-error-label"
                            id="demo-simple-select-error"
                            label="Learning Activity"
                            value={learningActivity.type}
                            onChange={learningActivityChangeHandler}
                          >
                            {learningActivityFilter.map((listItem, index) => {
                              return (
                                <MenuItem key={index} value={listItem}>
                                  {listItem}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </FormControl>
                      {/* {learningActivity.type != "Not OTJ Learning Activity" && ( */}
                      <>
                        <Stack
                          sx={{
                            mt: 1,
                            mb: 1,
                            gap: "1rem",
                          }}
                        >
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enLocale}
                          >
                            <DateTimePicker
                              // maxDate={new Date(parseInt(data?.endDate))}
                              // minDate={new Date(parseInt(data?.startDate))}
                              maxDate={getDateFromUkString(data?.endDate)}
                              minDate={getDateFromUkString(data?.startDate)}
                              label="Start Date & Time"
                              name="startDate"
                              value={learningActivity.startDate}
                              onChange={handleStartDateChange}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onKeyDown={(e) => e.preventDefault()}
                                />
                              )}
                            />
                          </LocalizationProvider>

                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enLocale}
                          >
                            <DateTimePicker
                              // maxDate={new Date(parseInt(data?.endDate))}
                              // minDate={new Date(parseInt(data?.startDate))}
                              maxDate={getDateFromUkString(data?.endDate)}
                              minDate={getDateFromUkString(data?.startDate)}
                              label="End Date & Time"
                              name="endDate"
                              value={learningActivity.endDate}
                              onChange={handleEndDateChange}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onKeyDown={(e) => e.preventDefault()}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Stack>
                        <div className={classes.cardStyle}>
                          {/* Time Inout component */}
                          <TimeInput
                            setError={setError}
                            error={error}
                            learningActivity={learningActivity}
                            setLearningActivity={setLearningActivity}
                          />
                        </div>
                      </>
                      {/* )} */}
                    </AccordionDetails>
                  </Accordion>

                  {/* <Accordion className={classes.accordianBox}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Edit Occupational Standards</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <TextField
                        id="duties"
                        autoFocus
                        margin="dense"
                        label="Duties"
                        type="text"
                        multiline
                        fullWidth
                        name="duties"
                        onChange={occupationalStandardsChangeHandler}
                        value={occupationalStandards.duties}
                      />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="knowledge"
                        label="Knowledge"
                        type="text"
                        multiline
                        fullWidth
                        name="knowledge"
                        onChange={occupationalStandardsChangeHandler}
                        value={occupationalStandards.knowledge}
                      />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="skills"
                        label="Skills"
                        type="text"
                        multiline
                        fullWidth
                        name="skills"
                        onChange={occupationalStandardsChangeHandler}
                        value={occupationalStandards.skills}
                      />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="behaviour"
                        label="Behaviour"
                        type="text"
                        multiline
                        fullWidth
                        name="behaviour"
                        onChange={occupationalStandardsChangeHandler}
                        value={occupationalStandards.behaviour}
                      />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="others"
                        label="Others"
                        type="text"
                        multiline
                        fullWidth
                        name="others"
                        onChange={occupationalStandardsChangeHandler}
                        value={occupationalStandards.others}
                      />
                    </AccordionDetails>
                  </Accordion> */}
                </Fragment>
              )}
              <div className="modalFooter">
                <div
                  style={{
                    // paddingTop: "10px",
                    display: "flex",
                    flexDirection: "row-reverse",
                    // gap: "1rem",
                  }}
                >
                  <Button onClick={props.closeModal} className={classes.button}>
                    Cancel
                  </Button>
                  <Button
                    onClick={submitHandler}
                    className={classes.button}
                    style={{ marginRight: "1rem" }}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </FormGroup>
          </Box>
        </Drawer>
      )}
    </div>
  );
}
